import React, { useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";

const MessageList = ({ conversation }) => {
  const endOfMessagesRef = useRef(null);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversation?.length]);
  return (
    <div className="messages mt-2 md:mt-10 text-black dark:bg-black w-full">
      {conversation?.flatMap((msg, index) =>
        msg.content.split("\n").map((contentPart, partIndex) => {
          return (
            <React.Fragment key={`${index}-${partIndex}`}>
              {partIndex === 0 && (
                <div
                  className={`font-bold text-sm mt-2 pl-4 border-l dark:border-black light:border-white ${
                    msg.role === "user"
                      ? "text-gray-400"
                      : msg.role === "assistant"
                      ? "text-accent"
                      : "text-teal-500"
                  }`}
                >
                  {msg.role === "user"
                    ? "Client"
                    : msg.role === "assistant"
                    ? "Coach Nia"
                    : `Coach ${msg.role}`}
                </div>
              )}
              <div
                className={`border-l text-sm border-gray-800 pl-4 my-1 leading-tight ${
                  partIndex === 0 ? "mt-1" : "mt-3"
                }`}
              >
                <p className="text-md">{contentPart}</p>
              </div>
            </React.Fragment>
          );
        })
      )}
    </div>
  );
};

export default MessageList;
