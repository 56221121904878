import Dashboard from "./components/coach/Dashboard";
import Users from "./components/coach/user/Users";
import Protocol from "./components/coach/protocol/Protocol";
import User from "./components/coach/user/User";
import Calendar from "./components/coach/Calendar";
import Settings from "./components/coach/Settings";
import { CoachProvider } from "./CoachContext";
import { useState } from "react";
import { Routes, Route } from "react-router-dom";

function CoachRoutes() {
  return (
    <CoachProvider>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/clients" element={<Users />} />
        <Route path="/client" element={<User />} />
        <Route path="/protocol" element={<Protocol />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </CoachProvider>
  );
}

export default CoachRoutes;
