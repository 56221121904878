import React, { useState, useEffect } from "react";
import Layout from "../Layout";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import UserLogs from "./UserLogs";
import { format } from "date-fns";
import SummaryCard from "./SummaryCard";
import Topic from "./Topic";
import { debounce, set } from "lodash";
import UserProtocolEditor from "./UserProtocolEditor";
import axios from "axios";

const User = ({ userId, onClose, onCloseTitle }) => {
  const [user, setUser] = useState({});
  const [conversation, setConversation] = useState(user.log);
  const [headlineSummary, setHeadlineSummary] = useState(""); // New state for headline summary
  const name = user?.core_memory && user?.core_memory?.name;
  const [humanCoachContext, setHumanCoachContext] = useState("");
  const [loading, setLoading] = useState(true);

  // Add a debounced function to handle updates to the server
  const axiosWithAuth = () => {
    const token = localStorage.getItem("coachJwtToken");
    return axios.create({
      baseURL: "https://api.1440.ai/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axiosWithAuth().get(
          `/api/coach/users/${userId}`
        );
        setUser(response.data);
        setConversation(response.data.log);
        if (response.data.headline_summary) {
          setHeadlineSummary(response.data.headline_summary); // Set headline summary from the user data
        } else {
          setHeadlineSummary(
            "Currently, there is no user overview available for this client."
          ); // Set headline summary from the user data
        }
      } catch (error) {
        console.error("Failed to fetch user details:", error);
        if (error.response && error.response.status === 401) {
          // Handle unauthorized access (e.g., redirect to login)
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();

    const updateLogs = async () => {
      try {
        const response = await axiosWithAuth().get(
          `/api/coach/users/${userId}`
        );
        setConversation(response.data.log);
      } catch (error) {
        console.error("Failed to update logs:", error);
      }
    };

    const intervalId = setInterval(updateLogs, 5000);

    return () => clearInterval(intervalId);
  }, [userId]);

  const debounceUpdateUserContext = debounce(async (userId, context) => {
    try {
      const response = await axiosWithAuth().put(
        `/users/${userId}/context/${context}`
      );
    } catch (error) {
      console.error("Failed to update user context:", error);
    }
  }, 1500);

  const handleHumanCoachContextChange = (value) => {
    setHumanCoachContext(value);
    if (user.user_id) {
      debounceUpdateUserContext(user.user_id, value);
    }
  };

  const markSummaryAsRead = async (summaryId) => {
    const index = user.summaries.length - 1 - summaryId;
    setUser((prevUser) => {
      const updatedUser = { ...prevUser };
      updatedUser.summaries[index].read = true;
      return updatedUser;
    });
    try {
      await axiosWithAuth().put(`/users/${userId}/summary/${index}`);
    } catch (error) {
      console.error("Failed to mark summary as read:", error);
    }
  };

  const handleProtocolUpdate = (updatedProtocol) => {
    // Update the user's protocol in the state and possibly send an update to the server
  };

  let topics = [];
  try {
    topics = user?.topic_agents?.topics.split("\n\n") || [];
  } catch (error) {
    console.error("Failed to parse topics:", error);
  }

  const update_conversation = (message) => {
    setConversation([...conversation, message]);
  };

  const generateUniqueId = (topics) => {
    const maxId = topics.reduce((max, topic) => Math.max(max, topic.id), -1);
    return maxId + 1;
  };

  const [parsedTopics, setParsedTopics] = useState(
    topics.map((topic, index) => {
      const [name, description] = topic.split("\nDescription: ");
      return { id: index, name, note: description || "" };
    })
  );

  const updateTopicsEndpoint = async (userId, topicString) => {
    try {
      const encodedTopicString = encodeURIComponent(topicString);
      const response = await axiosWithAuth().put(
        `/users/${userId}/topics/${encodedTopicString}`
      );
    } catch (error) {
      console.error("Failed to update topics:", error);
    }
  };

  async function turnOffShutdownMode() {
    try {
      await axiosWithAuth().put(`/users/${user.user_id}/shutdown_mode`);
      setUser((prevUser) => {
        return { ...prevUser, shutdown_mode: false };
      });
    } catch (error) {
      console.error("Failed to turn off shutdown mode:", error);
    }
  }

  const handleTopicUpdate = async (topicId, updatedTopic) => {
    let newTopics = [...parsedTopics];
    const topicIndex = newTopics.findIndex((topic) => topic.id === topicId);

    if (updatedTopic === null) {
      newTopics = newTopics.filter((topic) => topic.id !== topicId);
    } else if (topicIndex > -1) {
      newTopics[topicIndex] = updatedTopic;
    } else {
      newTopics.push(updatedTopic);
    }

    const topicsString = newTopics
      .map((topic) => `${topic.name}\nDescription: ${topic.note}`)
      .join("\n\n");

    setParsedTopics(newTopics);
    await updateTopicsEndpoint(user.user_id, topicsString);
  };

  if (loading) {
    return (
      <Layout
        title="Loading..."
        rightElement={
          <button
            onClick={onClose}
            className="flex items-center bg-slate-200 py-2 px-3 rounded-lg text-slate-600"
          >
            <ArrowLeftIcon className="h-5 w-5 mr-2" />
            {onCloseTitle ? onCloseTitle : "Back"}
          </button>
        }
      >
        <div className="flex justify-center items-center h-screen">
          <svg
            className="animate-spin h-16 w-16 text-darkBlue"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 0116 0H4z"
            ></path>
          </svg>
        </div>
      </Layout>
    );
  }

  return (
    <Layout
      title={name ? name : user.user_id}
      rightElement={
        <button
          onClick={onClose}
          className="flex items-center bg-slate-200 py-2 px-3 rounded-lg text-slate-600"
        >
          <ArrowLeftIcon className="h-5 w-5 mr-2" />
          {onCloseTitle ? onCloseTitle : "Back"}
        </button>
      }
    >
      <div className="">
        {user.shutdown_mode && (
          <div className="p-6 mb-6 w-full rounded-lg bg-red-100 text-red-800 inline-block">
            <h2 className="text-2xl mb-4 border-b border-red-300 pb-2">
              Shutdown Mode Active
            </h2>
            <p>
              This user has been placed in temporary shutdown mode by the 1440
              system due to a high risk assessment. The user's access to the
              1440 platform and its features has been suspended until the
              situation can be evaluated. To regain access, the user must be
              cleared by a human coach who will review the circumstances and
              determine when it's safe for the user to resume using the
              platform.
            </p>
            <button
              onClick={turnOffShutdownMode}
              className="bg-red-200 hover:bg-red-300 text-red-800 font-medium py-2 px-4 rounded"
            >
              Turn off shutdown mode
            </button>
          </div>
        )}
        <div className="p-6 w-full rounded-lg bg-slate-100 text-slate-950 inline-block">
          <h2 className="text-2xl mb-6 border-b border-slate-300 pb-2">Chat</h2>
          <UserLogs
            conversation={conversation ? conversation : []}
            user_id={user.user_id}
            update_conversation={update_conversation}
          />
        </div>
        <div className="p-6 mt-6 w-full rounded-lg bg-slate-100 text-slate-950 inline-block">
          <h2 className="text-2xl mb-6 border-b border-slate-300 pb-2">
            Previous conversations
          </h2>
          <div className="grid gap-4 grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3">
            {user?.summaries &&
              user.summaries.length > 0 &&
              user.summaries
                .slice()
                .reverse()
                .map((summary, index) => (
                  <SummaryCard
                    key={index}
                    summary={summary}
                    summary_id={index}
                    onSummaryOpen={markSummaryAsRead}
                  />
                ))}
          </div>
        </div>

        <div className="p-6 mt-6 w-full rounded-lg bg-slate-100 text-slate-950 inline-block">
          <h2 className="text-2xl mb-2 border-b border-slate-300 pb-2">
            Client Overview
          </h2>
          <div className="p-2 bg-slate-100 rounded text-slate-700">
            {headlineSummary}
          </div>
        </div>

        <div className="p-6 mt-6 w-full rounded-lg bg-slate-100 text-slate-950 inline-block">
          <h2 className="text-2xl mb-6 border-b border-slate-300 pb-2">
            Update
          </h2>
          <h3 className="text-lg font-medium mt-8 mb-0 text-slate-700">
            Give context to 1440
          </h3>
          <div className="mt-0 mb-0">
            <input
              id="humanCoachContext"
              type="text"
              value={humanCoachContext}
              onChange={(e) => handleHumanCoachContextChange(e.target.value)}
              className="border border-slate-300 rounded py-2 px-4 w-full mt-2"
              placeholder="Context about this user and information about how to approach future conversations..."
            />
          </div>
          <h3 className="text-lg font-medium mt-8 mb-3 text-slate-700">
            Upcoming topics
          </h3>
          <div className="grid grid-flow-row auto-rows-max md:grid-cols-2 2xl:grid-cols-3 gap-4">
            {parsedTopics.map((topic) => (
              <Topic
                key={topic.id}
                topic={topic}
                onUpdate={(updatedTopic) =>
                  handleTopicUpdate(topic.id, updatedTopic)
                }
              />
            ))}
            <button
              className="bg-blue-100 hover:bg-blue-200 hover:text-blue-600 text-blue-400 font-medium py-2 px-4 rounded"
              onClick={() => {
                const newId = generateUniqueId(parsedTopics);
                handleTopicUpdate(newId, { id: newId, name: "", note: "" });
              }}
            >
              + Add New Topic
            </button>
          </div>
          <h2 className="text-xl mt-12 p-4 bg-slate-200 rounded text-slate-700 inline-block">
            Edit protocol
          </h2>
          <hr></hr>
          {user.user_id && (
            <UserProtocolEditor
              protocol={user.protocol}
              updateProtocol={handleProtocolUpdate}
              user_id={user.user_id}
            />
          )}
        </div>
        <div className="mb-20"></div>
      </div>
    </Layout>
  );
};

export default User;
