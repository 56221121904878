import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { CoachContext } from "../../../CoachContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import User from "./User";
import AddUserModal from "./AddUserModal";
import "./Users.css";

const Users = () => {
  const { coachId } = React.useContext(CoachContext);
  const [users, setUsers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const axiosWithAuth = () => {
    const token = localStorage.getItem("coachJwtToken");
    return axios.create({
      baseURL: "https://api.1440.ai/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  useEffect(() => {
    if (coachId) {
      setIsLoading(true);
      axiosWithAuth()
        .get(`/api/coach/users/${coachId}/basic`)
        .then((response) => {
          if (response.data.status === "success") {
            setUsers(response.data.users);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [coachId]);

  const handleAddUser = () => {
    setShowAddUserModal(true);
  };

  const handleRowClick = (user) => {
    setSelectedUser(user.user_id);
  };

  const getInitials = (name) => {
    if (!name) return "";
    const words = name.split(" ");
    if (words.length >= 2) {
      return (words[0][0] + words[1][0]).toUpperCase();
    }
    return name.substring(0, 2).toUpperCase();
  };

  const getUserImage = (user) => {
    if (user.picture) {
      return user.picture;
    }
    if (user.core_memory?.name) {
      const initials = getInitials(user.core_memory.name);
      return `https://ui-avatars.com/api/?name=${initials}&background=6CB7EE&color=fff`;
    }
    const hashString = user.user_id || "";
    const colors = [
      "0EA5E9",
      "6366F1",
      "EC4899",
      "14B8A6",
      "F59E0B",
      "8B5CF6",
      "EF4444",
      "10B981",
    ];
    const colorIndex = hashString
      .split("")
      .reduce((acc, char) => (acc + char.charCodeAt(0)) % colors.length, 0);
    return `https://ui-avatars.com/api/?name=&background=${colors[colorIndex]}&color=fff`;
  };

  if (selectedUser) {
    return (
      <User
        userId={selectedUser}
        onClose={() => setSelectedUser(null)}
        onCloseTitle="All clients"
      />
    );
  }
  const getMessageStats = (user) => {
    const totalTime = new Date(Date.now() - 7 * 52 * 24 * 60 * 60 * 1000);
    const yesterday = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000);
    const todaysMessages = user.log.filter(
      (log) => new Date(log?.time) > yesterday
    );
    const recentMessages = user.log.filter(
      (log) => new Date(log?.time) > totalTime
    );
    const aiMessages = recentMessages.filter(
      (log) => log?.role === "assistant"
    );
    const humanMessages = recentMessages.filter((log) => log?.role === "user");
    return {
      total: recentMessages.length,
      today: todaysMessages.length,
      ai: aiMessages.length,
      human: humanMessages.length,
    };
  };

  return (
    <>
      {showAddUserModal && (
        <AddUserModal
          onClose={() => setShowAddUserModal(false)}
          onAddUser={(data) => {
            if (data && "user" in data) {
              setUsers([...users, data["user"]]);
            }
            setShowAddUserModal(false);
          }}
        />
      )}
      <Layout
        title="Users Overview"
        rightElement={
          <button
            onClick={handleAddUser}
            className="inline-flex items-center px-4 py-2 border-blue-200 text-sm font-medium border rounded-md shadow-sm text-blue-800 bg-blue-50 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300"
          >
            Add New Client
          </button>
        }
        background="bg-slate-50"
      >
        <div className=" py-2 bg-slate-50">
          <div className="mb-6 grid grid-cols-1 gap-5 sm:grid-cols-4">
            <div className="bg-white overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                      />
                    </svg>
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">
                        Total Clients
                      </dt>
                      <dd className="flex items-baseline">
                        <div className="text-2xl font-semibold text-gray-900">
                          {users?.length || 0}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                      />
                    </svg>
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">
                        Messages Today
                      </dt>
                      <dd className="flex items-baseline">
                        <div className="text-2xl font-semibold text-gray-900">
                          {users?.reduce(
                            (acc, user) => acc + getMessageStats(user).today,
                            0
                          ) || 0}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 10V3L4 14h7v7l9-11h-7z"
                      />
                    </svg>
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">
                        AI Response Rate
                      </dt>
                      <dd className="flex items-baseline">
                        <div className="text-2xl font-semibold text-gray-900">
                          {users ? "99.8%" : "-"}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">
                        Avg Response Time
                      </dt>
                      <dd className="flex items-baseline">
                        <div className="text-2xl font-semibold text-gray-900">
                          {users ? "1.2s" : "-"}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr className="bg-gray-50">
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Client
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Messages
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Last Message
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Member Since
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {isLoading ? (
                    <tr>
                      <td colSpan="5" className="px-6 py-4 text-center">
                        Loading users...
                      </td>
                    </tr>
                  ) : users?.length > 0 ? (
                    users.map((user) => {
                      const messageStats = getMessageStats(user);
                      return (
                        <tr
                          key={user.user_id}
                          className="hover:bg-gray-50 cursor-pointer"
                          onClick={() => handleRowClick(user)}
                        >
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 h-10 w-10">
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={getUserImage(user)}
                                  alt=""
                                />
                              </div>
                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">
                                  {user.core_memory?.name || user.user_id}
                                </div>
                                <div className="text-sm text-gray-500">
                                  Last active{" "}
                                  {formatLastActive(
                                    user.log[user.log?.length - 1]?.time
                                  )}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                user.shutdown_mode
                                  ? "bg-red-100 text-red-800"
                                  : "bg-green-100 text-green-800"
                              }`}
                            >
                              {user.shutdown_mode ? "Shutdown" : "Active"}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              Total: {messageStats.total}
                            </div>
                            <div className="text-xs text-gray-500">
                              AI: {messageStats.ai} | Human:{" "}
                              {messageStats.human}
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            <div className="text-sm text-gray-900">
                              {(
                                user.log[user.log.length - 1]?.content ||
                                "No messages"
                              ).substring(0, 100)}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {user.created_at
                              ? user.created_at
                                  .split(" ")[0]
                                  .slice(2)
                                  .replace(/-/g, "/")
                              : "-"}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5" className="px-6 py-4 text-center">
                        No users found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

function formatLastActive(lastUpdated) {
  const last = new Date(lastUpdated);
  if (isNaN(last.getTime())) return "0 days ago";
  const now = new Date();
  const diff = now - last;
  const diffMinutes = Math.floor(diff / 60000);
  if (diffMinutes < 60) return `${diffMinutes} minutes ago`;
  const diffHours = Math.floor(diff / 3600000);
  if (diffHours < 24) return `${diffHours} hours ago`;
  const diffDays = Math.floor(diff / 86400000);
  return `${diffDays} days ago`;
}

export default Users;
