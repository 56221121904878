import React, { useState } from "react";
import { Bars3BottomRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  CalendarIcon,
  PhoneIcon,
  ChartBarIcon,
  UserGroupIcon,
  CpuChipIcon,
  ClipboardDocumentCheckIcon,
  HeartIcon,
  ArrowPathIcon,
  ChatBubbleLeftRightIcon,
  SparklesIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/outline";
import Mixpanel from "mixpanel-browser";
import { WhatsappIcon } from "react-share";

const WhatsAppIcon = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className} fill="currentColor">
    <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
  </svg>
);
function NewYearsLanding() {
  const [isOpen, setIsOpen] = useState(false);

  const handleWhatsAppClick = () => {
    Mixpanel.track("WhatsApp Click", { source: "landing_page" });
    window.open("https://wa.me/+16203039554?text=Hello", "_blank");
  };

  const handleSMSClick = () => {
    Mixpanel.track("SMS Click", { source: "landing_page" });
    window.open("sms:+16203039554", "_blank");
  };

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Navigation */}
      <header className="fixed top-0 left-0 right-0 bg-black text-white z-50">
        <div className="container mx-auto flex justify-between items-center p-6">
          <div className="flex items-center gap-2">
            <img src="/images/logo-white.png" alt="Logo" className="h-8" />
          </div>
          <nav className="hidden md:flex font-medium space-x-6 items-center">
            <button
              onClick={handleWhatsAppClick}
              className="hover:text-[#CCFF00] text-white text-base flex items-center gap-2"
            >
              <WhatsAppIcon className="w-5 h-5" />
              WHATSAPP
            </button>
            <button
              onClick={handleSMSClick}
              className="hover:text-[#CCFF00] text-white text-base flex items-center gap-2"
            >
              <PhoneIcon className="w-5 h-5" />
              SMS
            </button>
            <button
              onClick={handleWhatsAppClick}
              className="bg-[#CCFF00] text-black px-6 py-2 rounded flex items-center gap-2 hover:bg-[#CCFF00]/90"
            >
              <WhatsAppIcon className="w-5 h-5" />
              START NOW
            </button>
          </nav>
          <div className="md:hidden">
            <button onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? (
                <XMarkIcon className="h-6 w-6" />
              ) : (
                <Bars3BottomRightIcon className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>

        {isOpen && (
          <nav className="md:hidden bg-black text-white py-4 px-6">
            <button
              onClick={handleWhatsAppClick}
              className="block py-2 hover:text-[#CCFF00] w-full text-left flex items-center gap-2"
            >
              <WhatsAppIcon className="w-5 h-5" />
              WHATSAPP
            </button>
            <button
              onClick={handleSMSClick}
              className="block py-2 hover:text-[#CCFF00] w-full text-left flex items-center gap-2"
            >
              <PhoneIcon className="w-5 h-5" />
              SMS
            </button>
            <button
              onClick={handleWhatsAppClick}
              className="w-full mt-4 bg-[#CCFF00] text-black px-6 py-2 rounded flex items-center gap-2 justify-center hover:bg-[#CCFF00]/90"
            >
              <WhatsAppIcon className="w-5 h-5" />
              START NOW
            </button>
          </nav>
        )}
      </header>

      {/* Main Content */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 pb-16 relative">
        {/* Background Effects */}
        <div className="absolute top-0 left-1/2 -translate-x-1/2 w-full h-full">
          <div className="absolute top-20 left-1/4 w-72 h-72 bg-[#CCFF00] rounded-full opacity-10 blur-[100px]" />
          <div className="absolute top-40 right-1/4 w-72 h-72 bg-[#CCFF00] rounded-full opacity-10 blur-[100px]" />
        </div>

        {/* Hero Section */}
        <div className="text-center relative z-10 mb-16">
          <div className="inline-block mb-4">
            <div className="inline-flex items-center px-3 py-1 rounded-full border border-[#CCFF00]/30 bg-[#CCFF00]/10 text-[#CCFF00] text-sm">
              <span className="animate-pulse mr-2">●</span> New Year's
              Resolutions Coach
            </div>
          </div>
          <h1 className="text-5xl md:text-7xl font-bold mb-6 bg-clip-text leading-tight text-transparent bg-gradient-to-b from-[#EEE] to-[#9E9E9E]">
            Your AI-Powered
            <br />
            Resolution Coach
          </h1>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto mb-8">
            Transform your resolutions into reality with personalized coaching
            that adapts to your schedule, health data, and goals.
          </p>
          <div className="flex flex-col items-center gap-4">
            <button
              onClick={handleWhatsAppClick}
              className="inline-flex items-center justify-center px-8 py-4 rounded-full bg-[#CCFF00] text-black font-semibold text-lg transition-all duration-200 hover:scale-105 hover:shadow-lg hover:shadow-[#CCFF00]/25 gap-2"
            >
              <WhatsAppIcon className="w-6 h-6" />
              Start Your Journey
            </button>
            <a
              href="https://www.producthunt.com/posts/new-year-s-resolution-coach-by-1440?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-new&#0045;year&#0045;s&#0045;resolution&#0045;coach&#0045;by&#0045;1440"
              target="_blank"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=751410&theme=dark"
                alt="New&#0032;Year&#0039;s&#0032;Resolution&#0032;Coach&#0032;by&#0032;1440 - A&#0032;connected&#0032;coach&#0032;to&#0032;actually&#0032;achieve&#0032;your&#0032;2025&#0032;resolutions | Product Hunt"
                style={{ width: "250px", height: "54px" }}
                width="250"
                height="54"
              />
            </a>
          </div>
        </div>
        {/* Integration Grid */}
        <div className="grid md:grid-cols-3 gap-8 relative z-10 mb-16 md:mb-32">
          <div className="p-8 bg-gray-900/40 backdrop-blur-xl rounded-2xl border border-[#CCFF00]/20">
            <CalendarIcon className="w-8 h-8 text-[#CCFF00] mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-white">
              Calendar Integration
            </h3>
            <p className="text-gray-400">
              Syncs with your schedule to read and write calendar events,
              providing support when you need it most
            </p>
          </div>
          <div className="p-8 bg-gray-900/40 backdrop-blur-xl rounded-2xl border border-[#CCFF00]/20">
            <HeartIcon className="w-8 h-8 text-[#CCFF00] mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-white">
              Health Monitoring
            </h3>
            <p className="text-gray-400">
              Connects with your health data to track progress and optimize
              performance
            </p>
          </div>

          <div className="p-8 bg-gray-900/40 backdrop-blur-xl rounded-2xl border border-[#CCFF00]/20">
            <ChatBubbleLeftRightIcon className="w-8 h-8 text-[#CCFF00] mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-white">
              Communication
            </h3>
            <p className="text-gray-400">
              Multi-channel support through WhatsApp, calls, and SMS
            </p>
          </div>
        </div>

        {/* Problem Section */}
        <div className="relative z-10 mb-40">
          {/* Visual Element */}
          <div className="absolute -top-16 left-1/2 -translate-x-1/2 w-40 h-40 bg-[#CCFF00]/10 rounded-full blur-3xl" />

          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-4xl md:text-5xl font-bold mb-8 text-slate-100">
              The Problem & Our Solution
            </h2>
            <p className="text-xl text-gray-400 mb-20 max-w-3xl mx-auto">
              Traditional resolution-setting is broken. We combined behavioral
              science with AI to create a coach that adapts to your unique
              challenges and keeps you accountable every step of the way.
            </p>
            {/* Stats Bar */}
            <div className="max-w-4xl mx-auto mb-12">
              <div className="bg-gray-900/40 backdrop-blur-xl rounded-2xl border border-[#CCFF00]/20 p-8">
                <div className="flex flex-col md:flex-row items-center justify-between gap-8">
                  <div className="text-center md:text-left">
                    <h3 className="text-5xl font-bold text-[#CCFF00] mb-2">
                      80%
                    </h3>
                    <p className="text-gray-400">
                      of resolutions fail by February
                    </p>
                  </div>
                  <div className="h-16 w-px bg-[#CCFF00]/20 hidden md:block" />
                  <div className="text-center md:text-left">
                    <h3 className="text-5xl font-bold text-[#CCFF00] mb-2">
                      92%
                    </h3>
                    <p className="text-gray-400">never achieve their goals</p>
                  </div>
                  <div className="h-16 w-px bg-[#CCFF00]/20 hidden md:block" />
                  <div className="text-center md:text-left">
                    <h3 className="text-5xl font-bold text-[#CCFF00] mb-2">
                      3x
                    </h3>
                    <p className="text-gray-400">
                      more likely to succeed with coach
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center mb-8">
              <svg
                className="w-6 h-6 text-[#CCFF00]/40 animate-bounce"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 4L12 20M12 20L18 14M12 20L6 14"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="group p-8 bg-gray-900/40 backdrop-blur-xl rounded-2xl border border-[#CCFF00]/20 transition-all duration-300 hover:bg-gray-900/60 hover:border-[#CCFF00]/40">
                <div className="w-16 h-16 mx-auto mb-6 bg-[#CCFF00]/10 rounded-full flex items-center justify-center">
                  <UserGroupIcon className="w-8 h-8 text-[#CCFF00]" />
                </div>
                <h3 className="text-xl font-semibold mb-4 text-white group-hover:text-[#CCFF00] transition-colors">
                  Personal Accountability
                </h3>
                <p className="text-gray-400">
                  AI-powered check-ins and real-time adjustments keep you
                  motivated and on track
                </p>
              </div>

              <div className="group p-8 bg-gray-900/40 backdrop-blur-xl rounded-2xl border border-[#CCFF00]/20 transition-all duration-300 hover:bg-gray-900/60 hover:border-[#CCFF00]/40">
                <div className="w-16 h-16 mx-auto mb-6 bg-[#CCFF00]/10 rounded-full flex items-center justify-center">
                  <RocketLaunchIcon className="w-8 h-8 text-[#CCFF00]" />
                </div>
                <h3 className="text-xl font-semibold mb-4 text-white group-hover:text-[#CCFF00] transition-colors">
                  Smart Planning
                </h3>
                <p className="text-gray-400">
                  Dynamic goal-setting that adapts to your progress and
                  lifestyle changes
                </p>
              </div>

              <div className="group p-8 bg-gray-900/40 backdrop-blur-xl rounded-2xl border border-[#CCFF00]/20 transition-all duration-300 hover:bg-gray-900/60 hover:border-[#CCFF00]/40">
                <div className="w-16 h-16 mx-auto mb-6 bg-[#CCFF00]/10 rounded-full flex items-center justify-center">
                  <SparklesIcon className="w-8 h-8 text-[#CCFF00]" />
                </div>
                <h3 className="text-xl font-semibold mb-4 text-white group-hover:text-[#CCFF00] transition-colors">
                  Sustained Motivation
                </h3>
                <p className="text-gray-400">
                  Behavioral science-backed techniques to build lasting habits
                  and momentum
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Features Section */}
        <div className="relative z-10 mb-16">
          <h2 className="text-3xl font-bold mb-12 text-white text-center">
            Comprehensive Performance System
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                icon: <CpuChipIcon className="w-8 h-8 text-[#CCFF00]" />,
                title: "Memory Analysis",
                description:
                  "Learns from your patterns and behaviors to provide personalized guidance",
              },
              {
                icon: <ChartBarIcon className="w-8 h-8 text-[#CCFF00]" />,
                title: "Semantic Analysis",
                description:
                  "Understands context and nuance in your communication",
              },
              {
                icon: (
                  <ClipboardDocumentCheckIcon className="w-8 h-8 text-[#CCFF00]" />
                ),
                title: "Assessments",
                description: "Regular check-ins and progress tracking",
              },
              {
                icon: <HeartIcon className="w-8 h-8 text-[#CCFF00]" />,
                title: "Psychomarkers",
                description: "Monitors emotional and psychological well-being",
              },
              {
                icon: <UserGroupIcon className="w-8 h-8 text-[#CCFF00]" />,
                title: "Relationship Tracking",
                description:
                  "Helps maintain accountability and support networks",
              },
              {
                icon: <ArrowPathIcon className="w-8 h-8 text-[#CCFF00]" />,
                title: "Performance Metrics",
                description: "Detailed analytics and progress visualization",
              },
            ].map((feature, index) => (
              <div
                key={index}
                className="p-6 bg-gray-900/40 backdrop-blur-xl rounded-2xl border border-[#CCFF00]/20"
              >
                <div className="mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-2 text-white">
                  {feature.title}
                </h3>
                <p className="text-gray-400">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Stats Section */}
        <div className="relative z-10 mb-16">
          <div className="grid md:grid-cols-3 gap-8 text-center">
            <div className="p-6">
              <div className="text-4xl font-bold text-[#CCFF00] mb-2">24/7</div>
              <p className="text-gray-400">Always-on support</p>
            </div>
            <div className="p-6">
              <div className="text-4xl font-bold text-[#CCFF00] mb-2">8.3%</div>
              <p className="text-gray-400">Edge on traditional coaching</p>
            </div>
            <div className="p-6">
              <div className="text-4xl font-bold text-[#CCFF00] mb-2">2s</div>
              <p className="text-gray-400">Average response time</p>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="relative">
          <div className="absolute inset-0 bg-gradient-to-r from-[#CCFF00]/20 to-[#9EFF00]/20 rounded-3xl blur-xl"></div>
          <div className="relative bg-gray-900/60 backdrop-blur-xl rounded-3xl border border-[#CCFF00]/20 p-12 text-center">
            <h2 className="text-3xl font-bold mb-4 text-white">
              Ready to Transform Your Resolutions?
            </h2>
            <p className="text-gray-400 mb-8 max-w-2xl mx-auto">
              Join thousands who are achieving their goals with personalized,
              data-driven coaching support.
            </p>
            <button
              onClick={handleWhatsAppClick}
              className="inline-flex items-center justify-center px-8 py-4 rounded-full bg-[#CCFF00] text-black font-semibold text-lg transition-all duration-200 hover:scale-105 hover:shadow-lg hover:shadow-[#CCFF00]/25 gap-2"
            >
              <WhatsAppIcon className="w-6 h-6" />
              Get Started Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewYearsLanding;
