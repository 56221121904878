import React from "react";
import {
  BarChart,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  Users,
  Target,
  BookOpen,
  Send,
  CheckCircle,
  Brain,
  Briefcase,
  ArrowRight,
  Activity,
  MapPin,
} from "lucide-react";

const CareerDashboard = () => {
  const activityData = [
    { week: "W1", applications: 5, responses: 2, interviews: 1 },
    { week: "W2", applications: 8, responses: 4, interviews: 2 },
    { week: "W3", applications: 6, responses: 3, interviews: 2 },
    { week: "W4", applications: 10, responses: 5, interviews: 3 },
  ];

  const skillProgress = [
    { name: "Product Strategy", current: 75, target: 90 },
    { name: "Ed Tech Knowledge", current: 95, target: 95 },
    { name: "UX/UI Design", current: 60, target: 80 },
    { name: "Agile/Scrum", current: 70, target: 85 },
  ];

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      {/* Profile Overview */}
      <div className="bg-white rounded-2xl p-6 shadow-sm mb-6">
        <div className="flex items-start gap-6">
          {/* <img src="/api/placeholder/80/80" alt="John" className="w-20 h-20 rounded-full" /> */}
          <div className="flex-1">
            <div className="flex items-start justify-between mb-4">
              <div>
                <h1 className="text-2xl font-bold mb-1">John</h1>
                <p className="text-gray-600">
                  Former Dean of Technology → Product & Curriculum Designer
                </p>
                <div className="flex gap-3 mt-2">
                  <span className="bg-blue-50 text-blue-600 px-3 py-1 rounded-full text-sm font-medium">
                    Career Transition
                  </span>
                  <span className="bg-green-50 text-green-600 px-3 py-1 rounded-full text-sm font-medium flex items-center gap-1">
                    <MapPin className="w-4 h-4" /> Remote Only
                  </span>
                </div>
              </div>
              <div className="text-right">
                <div className="text-sm font-medium text-gray-600">
                  Current Salary
                </div>
                <div className="text-xl font-bold">$120,000</div>
                <div className="text-sm font-medium text-gray-600 mt-2">
                  Target Salary
                </div>
                <div className="text-xl font-bold text-blue-600">$220,000</div>
              </div>
            </div>
            <div className="space-y-2">
              <div>
                <div className="flex items-center justify-between mb-1">
                  <span className="text-sm font-medium">
                    Transition Progress
                  </span>
                  <span className="text-sm text-gray-600">45%</span>
                </div>
                <div className="w-full bg-gray-100 rounded-full h-2">
                  <div
                    className="bg-blue-600 h-2 rounded-full"
                    style={{ width: "45%" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Key Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
        {/* Job Search Progress */}
        <div className="bg-white rounded-2xl p-6 shadow-sm">
          <div className="flex items-center justify-between mb-6">
            <h2 className="font-semibold">Job Search Activity</h2>
            <Activity className="w-5 h-5 text-blue-600" />
          </div>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={activityData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="week" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="applications" stroke="#2563EB" />
                <Line type="monotone" dataKey="responses" stroke="#16A34A" />
                <Line type="monotone" dataKey="interviews" stroke="#EA580C" />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="flex justify-between mt-4 text-sm">
            <div className="text-blue-600">Applications</div>
            <div className="text-green-600">Responses</div>
            <div className="text-orange-600">Interviews</div>
          </div>
        </div>

        {/* Network Insights */}
        <div className="bg-white rounded-2xl p-6 shadow-sm">
          <div className="flex items-center justify-between mb-6">
            <h2 className="font-semibold">Key Connections</h2>
            <Users className="w-5 h-5 text-blue-600" />
          </div>
          <div className="space-y-4">
            <div className="p-4 bg-gray-50 rounded-xl">
              <div className="flex items-center gap-4">
                {/* <img src=""  className="w-10 h-10 rounded-full" /> */}
                <div>
                  <div className="font-medium">Sarah Miller</div>
                  <div className="text-sm text-gray-600">
                    Product Director at Coursera
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 bg-gray-50 rounded-xl">
              <div className="flex items-center gap-4">
                {/* <img src=""  className="w-10 h-10 rounded-full" /> */}
                <div>
                  <div className="font-medium">Michael Chen</div>
                  <div className="text-sm text-gray-600">
                    CPO at Khan Academy
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4 mt-4">
              <div className="bg-blue-50 p-3 rounded-xl text-center">
                <div className="text-2xl font-bold text-blue-600">6</div>
                <div className="text-sm text-gray-600">Ed Tech Contacts</div>
              </div>
              <div className="bg-blue-50 p-3 rounded-xl text-center">
                <div className="text-2xl font-bold text-blue-600">8</div>
                <div className="text-sm text-gray-600">Product Leaders</div>
              </div>
              <div className="bg-blue-50 p-3 rounded-xl text-center">
                <div className="text-2xl font-bold text-blue-600">4</div>
                <div className="text-sm text-gray-600">Warm Intros</div>
              </div>
            </div>
          </div>
        </div>

        {/* Skills Development */}
        <div className="bg-white rounded-2xl p-6 shadow-sm">
          <div className="flex items-center justify-between mb-6">
            <h2 className="font-semibold">Skill Development</h2>
            <Brain className="w-5 h-5 text-blue-600" />
          </div>
          <div className="space-y-6">
            {skillProgress.map((skill) => (
              <div key={skill.name}>
                <div className="flex items-center justify-between mb-2">
                  <span className="font-medium">{skill.name}</span>
                  <span className="text-sm text-gray-600">
                    {skill.current}%
                  </span>
                </div>
                <div className="w-full bg-gray-100 rounded-full h-2">
                  <div
                    className="bg-blue-600 h-2 rounded-full"
                    style={{ width: `${skill.current}%` }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Action Items */}
      <div className="bg-white rounded-2xl p-6 shadow-sm">
        <h2 className="font-semibold mb-6">Priority Actions</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="p-4 border border-gray-100 rounded-xl">
            <div className="flex items-center gap-3 mb-2">
              <div className="w-8 h-8 bg-blue-50 rounded-lg flex items-center justify-center">
                <Send className="w-4 h-4 text-blue-600" />
              </div>
              <span className="font-medium">Schedule Coursera Interview</span>
            </div>
            <p className="text-sm text-gray-600">
              Product Design Role - Remote Position
            </p>
          </div>
          <div className="p-4 border border-gray-100 rounded-xl">
            <div className="flex items-center gap-3 mb-2">
              <div className="w-8 h-8 bg-blue-50 rounded-lg flex items-center justify-center">
                <BookOpen className="w-4 h-4 text-blue-600" />
              </div>
              <span className="font-medium">
                Complete Product Strategy Course
              </span>
            </div>
            <p className="text-sm text-gray-600">3 modules remaining</p>
          </div>
          <div className="p-4 border border-gray-100 rounded-xl">
            <div className="flex items-center gap-3 mb-2">
              <div className="w-8 h-8 bg-blue-50 rounded-lg flex items-center justify-center">
                <Users className="w-4 h-4 text-blue-600" />
              </div>
              <span className="font-medium">Connect with Ed Tech Leaders</span>
            </div>
            <p className="text-sm text-gray-600">
              Virtual coffee chat with Khan Academy CPO
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerDashboard;
