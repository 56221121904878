import React, { useState, useEffect } from "react";
import {
  ArrowRight,
  Target,
  BookOpen,
  Briefcase,
  Terminal,
  Users,
  Brain,
  Sparkles,
  ArrowLeft,
} from "lucide-react";
import { Link } from "react-router-dom";

const CareerPlanner = () => {
  const [currentSalary, setCurrentSalary] = useState(75000);
  const [targetSalary, setTargetSalary] = useState(150000);
  const [role, setRole] = useState("");
  const [industry, setIndustry] = useState("");
  const [showReport, setShowReport] = useState(false);

  const formatSalary = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    }).format(value);
  };

  const generateRecommendations = () => {
    const gap = targetSalary - currentSalary;
    const recommendations = [];

    if (gap > 50000) {
      recommendations.push({
        icon: <Brain className="w-5 h-5" />,
        title: "AI Integration Skills",
        description:
          "Learn to work alongside AI tools to increase your value. Focus on prompt engineering and AI workflow optimization.",
        action: "Take AI readiness assessment",
      });
    }

    recommendations.push({
      icon: <BookOpen className="w-5 h-5" />,
      title: "Skill Enhancement",
      description:
        "Develop key skills in data analysis and digital transformation",
      action: "View learning path",
    });

    if (gap > 30000) {
      recommendations.push({
        icon: <Terminal className="w-5 h-5" />,
        title: "Freelance Projects",
        description:
          "Build portfolio and additional income through strategic freelance work",
        action: "Browse projects",
      });
    }

    recommendations.push({
      icon: <Users className="w-5 h-5" />,
      title: "Network Expansion",
      description: "Connect with professionals in target salary range",
      action: "View opportunities",
    });

    return recommendations;
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Navigation */}
      <nav className="fixed w-full bg-white/80 backdrop-blur-md z-50 border-b border-gray-100">
        <div className="container mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="text-2xl font-bold text-blue-600">stride</div>
            <button className="bg-black text-white px-6 py-2 rounded-full text-sm font-medium hover:bg-gray-800 transition">
              Get Started
            </button>
          </div>
        </div>
      </nav>

      <div className="container mx-auto px-6 pt-28 pb-20">
        <div className="max-w-2xl mx-auto">
          {/* Header */}
          <div className="text-center mb-12">
            <div className="inline-block bg-blue-50 text-blue-600 px-4 py-2 rounded-full text-sm font-medium mb-6">
              Career Planning Tool
            </div>
            <h2 className="text-4xl font-bold mb-4">Map Your Career Growth</h2>
            <p className="text-gray-600">
              Get a personalized plan to reach your salary goals
            </p>
          </div>

          {/* Calculator Card */}
          <div className="bg-white rounded-3xl shadow-sm border border-gray-100 p-8">
            {!showReport ? (
              <>
                {/* Current Salary Slider */}
                <div className="mb-8">
                  <label className="block text-sm font-medium text-gray-600 mb-3">
                    Current Annual Salary
                  </label>
                  <input
                    type="range"
                    min="30000"
                    max="500000"
                    step="5000"
                    value={currentSalary}
                    onChange={(e) => setCurrentSalary(Number(e.target.value))}
                    className="w-full h-2 bg-gray-100 rounded-lg appearance-none cursor-pointer accent-blue-600"
                  />
                  <div className="text-2xl font-bold text-black mt-3">
                    {formatSalary(currentSalary)}
                  </div>
                </div>

                {/* Target Salary Slider */}
                <div className="mb-8">
                  <label className="block text-sm font-medium text-gray-600 mb-3">
                    Target Annual Salary
                  </label>
                  <input
                    type="range"
                    min="30000"
                    max="500000"
                    step="5000"
                    value={targetSalary}
                    onChange={(e) => setTargetSalary(Number(e.target.value))}
                    className="w-full h-2 bg-gray-100 rounded-lg appearance-none cursor-pointer accent-blue-600"
                  />
                  <div className="text-2xl font-bold text-black mt-3">
                    {formatSalary(targetSalary)}
                  </div>
                </div>

                {/* Role Selection */}
                <div className="mb-8">
                  <label className="block text-sm font-medium text-gray-600 mb-3">
                    Current Role
                  </label>
                  <select
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    className="w-full p-3 bg-gray-50 border border-gray-100 rounded-xl text-gray-900"
                  >
                    <option value="">Select your role</option>
                    <option value="software">Software Development</option>
                    <option value="data">Data Analysis</option>
                    <option value="marketing">Marketing</option>
                    <option value="sales">Sales</option>
                    <option value="operations">Operations</option>
                    <option value="admin">Administrative</option>
                  </select>
                </div>

                {/* Industry Selection */}
                <div className="mb-8">
                  <label className="block text-sm font-medium text-gray-600 mb-3">
                    Industry
                  </label>
                  <select
                    value={industry}
                    onChange={(e) => setIndustry(e.target.value)}
                    className="w-full p-3 bg-gray-50 border border-gray-100 rounded-xl text-gray-900"
                  >
                    <option value="">Select your industry</option>
                    <option value="tech">Technology</option>
                    <option value="finance">Finance</option>
                    <option value="healthcare">Healthcare</option>
                    <option value="retail">Retail</option>
                    <option value="manufacturing">Manufacturing</option>
                  </select>
                </div>

                {/* Generate Report Button */}
                <button
                  onClick={() => setShowReport(true)}
                  className="w-full bg-black text-white py-4 rounded-full font-medium hover:bg-gray-800 transition inline-flex items-center justify-center gap-2"
                >
                  Generate Career Plan
                  <ArrowRight className="w-4 h-4" />
                </button>
              </>
            ) : (
              // Report View
              <div>
                {/* Summary Stats */}
                <div className="grid grid-cols-2 gap-4 mb-8">
                  <div className="bg-gray-50 p-6 rounded-2xl">
                    <div className="text-sm text-gray-600 mb-1">
                      Growth Target
                    </div>
                    <div className="text-2xl font-bold">
                      {(
                        ((targetSalary - currentSalary) / currentSalary) *
                        100
                      ).toFixed(1)}
                      %
                    </div>
                  </div>
                  <div className="bg-gray-50 p-6 rounded-2xl">
                    <div className="text-sm text-gray-600 mb-1">Timeline</div>
                    <div className="text-2xl font-bold">12-18 mo</div>
                  </div>
                </div>

                {/* Recommendations */}
                <div className="space-y-6">
                  <h3 className="text-xl font-bold mb-4">Your Growth Plan</h3>
                  {generateRecommendations().map((rec, index) => (
                    <div key={index} className="bg-gray-50 p-6 rounded-2xl">
                      <div className="flex items-start gap-4">
                        <div className="bg-blue-100 p-2 rounded-xl">
                          {rec.icon}
                        </div>
                        <div>
                          <h4 className="font-semibold mb-2">{rec.title}</h4>
                          <p className="text-gray-600 text-sm mb-4">
                            {rec.description}
                          </p>
                          <button className="text-blue-600 text-sm font-medium hover:text-blue-700 transition inline-flex items-center gap-1">
                            {rec.action}
                            <ArrowRight className="w-4 h-4" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* CTA */}
                <div className="mt-8 pt-8 border-t border-gray-100">
                  <Link to="/stride_dashboard">
                    <button className="w-full bg-black text-white py-4 rounded-full font-medium hover:bg-gray-800 transition">
                      Get Your Sample Full Career Plan
                    </button>
                  </Link>

                  <button
                    onClick={() => setShowReport(false)}
                    className="w-full mt-4 text-gray-600 py-4 rounded-full font-medium hover:text-gray-800 transition inline-flex items-center justify-center gap-2"
                  >
                    <ArrowLeft className="w-4 h-4" />
                    Adjust Inputs
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Trust Elements */}
          <div className="text-center text-sm text-gray-600 mt-8">
            Join 50,000+ professionals who've mapped their career growth with
            Stride
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerPlanner;
