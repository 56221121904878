import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Personas from "./Personas"; // Add this import

export default function AIConversations({ onSelectConversation }) {
  const [testingUsers, setTestingUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeConversations, setActiveConversations] = useState([]);
  const [userId, setuserId] = useState("");
  const [numMessages, setNumMessages] = useState(20);
  const messagesEndRef = useRef(null);
  const [userModel, setUserModel] = useState(
    "ft:gpt-4o-2024-08-06:sharelabs:user-1440:ANJdXZC4"
  );
  const [userPrompt, setUserPrompt] = useState(
    "You are a coaching client engaging in a text-based conversation. Do not repeat a message if you have already sent it before. Never pretend that you are the coach. You are the user. Respond realistically to the coach, as if you were a human client in a text-based coaching conversation. Write your response to the latest message with no text before or after:\n"
  );

  useEffect(() => {
    fetchTestingUsers();
    return () => {
      // Cleanup all EventSources on component unmount
      activeConversations.forEach((conv) => {
        if (conv.eventSource) {
          conv.eventSource.close();
        }
      });
    };
  }, []);

  const scrollToBottom = (conversationId) => {
    const element = document.getElementById(`messages-${conversationId}`);
    element?.scrollTo({
      top: element.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [activeConversations]);

  const fetchTestingUsers = async () => {
    try {
      // Save current scroll position
      const conversationsContainer =
        document.querySelector(".overflow-y-scroll");
      const scrollPosition = conversationsContainer?.scrollTop;

      const response = await axios.get(
        "https://api.1440.ai/api/admin/testing-conversations",
        {
          headers: {
            "Api-Key": localStorage.getItem("adminApiKey"),
          },
        }
      );
      setTestingUsers(response.data);

      // Restore scroll position after state update
      setTimeout(() => {
        if (conversationsContainer && scrollPosition) {
          conversationsContainer.scrollTop = scrollPosition;
        }
      }, 0);
    } catch (error) {
      console.error("Error fetching testing users:", error);
    } finally {
      setLoading(false);
    }
  };

  const startNewConversation = async () => {
    const conversationState = {
      id: null,
      messages: [],
      isGenerating: true,
      eventSource: null,
    };

    setActiveConversations((prev) => [...prev, conversationState]);

    try {
      const response = await axios.post(
        "https://api.1440.ai/api/admin/start-conversation",
        {
          userId: userId || undefined,
          num_messages: numMessages || undefined,
          user_model: userModel,
          user_prompt: userPrompt,
        },
        {
          headers: {
            "Api-Key": localStorage.getItem("adminApiKey"),
          },
        }
      );

      const data = response.data;
      const newEventSource = new EventSource(
        `https://api.1440.ai/api/admin/conversation-stream/${
          data.user_id
        }/${localStorage.getItem("adminApiKey")}`
      );

      // In the newEventSource.onmessage handler
      newEventSource.onmessage = (event) => {
        const message = JSON.parse(event.data);
        setActiveConversations((prev) =>
          prev.map((conv) =>
            conv.id === data.user_id
              ? { ...conv, messages: [...conv.messages, message] }
              : conv
          )
        );
        // Scroll only this specific conversation
        setTimeout(() => scrollToBottom(data.user_id), 100);
      };

      newEventSource.onerror = (error) => {
        console.error("EventSource error:", error);
        newEventSource.close();
        setActiveConversations((prev) =>
          prev.map((conv) =>
            conv.id === data.user_id
              ? { ...conv, isGenerating: false, eventSource: null }
              : conv
          )
        );
      };

      setActiveConversations((prev) =>
        prev.map((conv) =>
          !conv.id
            ? {
                ...conv,
                id: data.user_id,
                eventSource: newEventSource,
              }
            : conv
        )
      );

      fetchTestingUsers();
    } catch (error) {
      console.error("Error starting conversation:", error);
      setActiveConversations((prev) => prev.filter((conv) => conv.id !== null));
    }
  };

  const removeConversation = (conversationId) => {
    setActiveConversations((prev) => {
      const updatedConversations = prev.filter(
        (conv) => conv.id !== conversationId
      );
      const conversation = prev.find((conv) => conv.id === conversationId);
      if (conversation?.eventSource) {
        conversation.eventSource.close();
      }
      return updatedConversations;
    });
  };

  const cancelConversation = (conversationId) => {
    const conversation = activeConversations.find(
      (conv) => conv.id === conversationId
    );
    if (conversation?.eventSource) {
      conversation.eventSource.close();
    }
    removeConversation(conversationId);
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-white">AI Conversations</h1>
      </div>
      <div className="bg-slate-800 flex-grow rounded-lg p-4">
        <h2 className="text-lg font-medium text-white mb-2">
          All AI Conversations
        </h2>
        {loading ? (
          <div className="text-gray-400 text-center py-4">
            Loading AI conversations...
          </div>
        ) : (
          <div className="h-64 grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-3 overflow-y-scroll">
            {testingUsers.map((user) => (
              <div
                key={user.user_id}
                onClick={() => onSelectConversation(user.user_id)}
                className="bg-slate-700 px-2 py-2 rounded-lg hover:bg-slate-600 cursor-pointer"
              >
                <div className="text-slate-100 text-sm font-medium flex justify-between items-center">
                  <span>
                    {user.user_id.replace(
                      /testing_(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
                      (_, year, month, day, hour, min, sec) => {
                        const months = [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ];
                        return `${
                          months[parseInt(month) - 1]
                        } ${day} ${hour}:${min}:${sec}`;
                      }
                    )}
                  </span>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(user.user_id);
                    }}
                    className="text-slate-400 hover:text-slate-100"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                      <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
                    </svg>
                  </button>
                </div>
                <div className="text-slate-400 text-xs">
                  {user.log?.length || 0} messages
                  {user.config?.userId ? " " + user?.config?.userId : ""}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="grid grid-cols-1 gap-4">
        <div className="flex justify-between space-x-4">
          <div className="bg-slate-800 flex-grow rounded-lg p-4">
            <div className="space-y-4">
              <div>
                <div>
                  <label className="block text-gray-300 mb-2">Model</label>
                  <select
                    value={userModel}
                    onChange={(e) => setUserModel(e.target.value)}
                    className="w-full px-3 py-2 bg-slate-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="ft:gpt-4o-2024-08-06:sharelabs:user-1440:ANJdXZC4">
                      Fine-tuned based on 1440 data
                    </option>
                    <option value="claude-3-5-haiku-latest">
                      claude-3-5-haiku-latest
                    </option>
                    <option value="claude-3-5-sonnet-latest">
                      claude-3-5-sonnet-latest
                    </option>
                    <option value="gpt-4o">gpt-4o</option>
                    <option value="gpt-4o-mini">gpt-4o-mini</option>
                  </select>
                </div>

                <div>
                  <label className="block text-gray-300 mb-2">
                    System Prompt
                  </label>
                  <textarea
                    value={userPrompt}
                    onChange={(e) => setUserPrompt(e.target.value)}
                    rows={4}
                    className="w-full px-3 py-2 bg-slate-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <label className="block text-gray-300 mb-2">
                  ID to duplicate (Optional)
                </label>
                <input
                  type="text"
                  value={userId}
                  onChange={(e) => setuserId(e.target.value)}
                  placeholder="Enter userId..."
                  className="w-full px-3 py-2 bg-slate-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2">
                  Number of Messages
                </label>
                <input
                  type="number"
                  value={numMessages}
                  onChange={(e) => setNumMessages(parseInt(e.target.value))}
                  min="1"
                  max="50"
                  className="w-full px-3 py-2 bg-slate-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <button
                onClick={startNewConversation}
                className="w-full px-4 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white"
              >
                Generate conversation
              </button>
            </div>
          </div>

          <div className="flex-grow p-4 bg-slate-800 rounded-lg">
            <Personas />
          </div>
        </div>
        <div className="overflow-x-auto">
          <div className="flex gap-4" style={{ minWidth: "min-content" }}>
            {[...activeConversations].reverse().map((conversation) => (
              <div
                key={conversation.id || "pending"}
                className="bg-slate-800 rounded-lg p-4 w-[400px] flex-shrink-0"
              >
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-white text-lg font-medium">
                    <div>AI conversation</div>
                    <div className="text-sm text-slate-400">
                      {conversation.id ? `${conversation.id}` : "Starting..."}
                    </div>
                  </h3>
                  <div className="space-x-2">
                    {conversation.isGenerating ? (
                      <button
                        onClick={() => cancelConversation(conversation.id)}
                        className="px-3 py-1 bg-red-600 hover:bg-red-700 text-white rounded text-sm"
                      >
                        Cancel
                      </button>
                    ) : (
                      <button
                        onClick={() => removeConversation(conversation.id)}
                        className="px-3 py-1 bg-gray-600 hover:bg-gray-700 text-white rounded text-sm"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>

                <div
                  id={`messages-${conversation.id}`}
                  className="h-[400px] overflow-y-auto"
                >
                  {conversation.messages.length === 0 ? (
                    <div className="text-gray-400 text-center mt-4">
                      Starting conversation...
                    </div>
                  ) : (
                    conversation.messages.map((message, index) => (
                      <div
                        key={index}
                        className={`mb-4 ${
                          message.role === "assistant"
                            ? "text-blue-400"
                            : "text-green-400"
                        }`}
                      >
                        <span className="font-bold">
                          {message.role === "assistant" ? "Coach: " : "User: "}
                        </span>
                        <span className="text-white">{message.content}</span>
                      </div>
                    ))
                  )}
                  <div ref={messagesEndRef} />
                </div>

                {conversation.isGenerating && (
                  <div className="text-gray-400 animate-pulse mt-4">
                    Generating conversation...
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
