import React from "react";
import EditUserProtocol from "../protocol/EditUserProtocol";

const UserProtocolEditor = ({ protocol, updateProtocol, user_id }) => {
  // Handle the protocol update logic here if needed

  return (
    <EditUserProtocol
      protocol={protocol}
      updateProtocol={updateProtocol}
      isUserProtocol={user_id}
    />
  );
};

export default UserProtocolEditor;
