import React, { useEffect } from "react";
import Layout from "./Layout";
import { useAuth0 } from "@auth0/auth0-react"; // Import useAuth0 hook
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const Settings = () => {
  const { user, logout, isLoading } = useAuth0();
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [initialPhoneNumber, setInitialPhoneNumber] = React.useState(""); // State for initial phone number
  const [successMessage, setSuccessMessage] = React.useState(""); // State for success message

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };

  const handleSignOut = () => {
    localStorage.clear();
    logout({ returnTo: "https://1440.ai/coach/dashboard" });
  };

  const axiosWithAuth = () => {
    const token = localStorage.getItem("coachJwtToken");
    return axios.create({
      baseURL: "https://api.1440.ai/", // Ensure this is the correct base URL
      // baseURL: "http://localhost:8000/", // Ensure this is the correct base URL
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  // Fetch the phone number when the component mounts
  useEffect(() => {
    if (user && user.email) {
      const fetchPhoneNumber = async () => {
        try {
          const response = await axiosWithAuth().post(
            "/api/coach/reveal_phone",
            {
              email: user.email,
            }
          );
          if (response.data.status === "success") {
            setPhoneNumber(response.data.phoneNumber);
            setInitialPhoneNumber(response.data.phoneNumber); // Set initial phone number
          } else {
            console.error("Failed to fetch phone number.");
          }
        } catch (error) {
          console.error("Error fetching phone number:", error);
        }
      };

      fetchPhoneNumber(); // Call the function immediately
    }
  }, [user]);

  // Function to handle form submission for updating phone number
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosWithAuth().post("/api/coach/update_phone", {
        email: user.email,
        phoneNumber: phoneNumber,
      });
      if (response.data.status === "success") {
        setSuccessMessage("Phone number updated successfully!"); // Set success message
      } else {
        setSuccessMessage("Failed to update phone number."); // Set failure message
      }
    } catch (error) {
      console.error("Error updating phone number:", error);
      console.error("Error details:", {
        message: error.message,
        name: error.name,
        code: error.code,
        config: error.config,
        request: error.request,
        response: error.response,
      });
      setSuccessMessage("An error occurred while updating the phone number."); // Set error message
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout title="Settings">
      <div>
        <div className="rounded w-fit bg-slate-100 py-4 mb-4 px-6 border border-gray-300">
          <div className="flex items-center">
            <img
              src={user?.picture}
              alt="User"
              className="w-12 h-12 rounded-full mr-4"
            />
            <div>
              <p className="text-lg font-bold mb-0">{user?.name}</p>
              <p className="text-gray-600 mb-0">{user?.email}</p>
            </div>
          </div>
        </div>
        <form className="p-4 border rounded shadow" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="phoneNumber"
            >
              Set a new Phone Number
            </label>
            <PhoneInput
              international
              defaultCountry="US"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder="Enter phone number"
              className="form-input w-3/4 md:w-1/2 px-4 py-3 md:py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-darkBlue focus:border-transparent"
              inputClassName="w-full"
            />
            <p className="text-gray-500 text-sm mt-2">
              The phone number currently set to your account is:{" "}
              {initialPhoneNumber}
            </p>
            <p className="text-gray-500 text-sm mt-2">
              The phone number you set will be the one where you'll be
              pinged/contacted.
            </p>
          </div>
          <button
            type="submit"
            className="bg-blue-100 hover:bg-blue-200 text-blue-900 font-medium py-2 px-4 rounded"
          >
            Update Phone Number
          </button>
        </form>
        {successMessage && (
          <p className="text-green-500 mt-4">{successMessage}</p> // Conditionally render success message
        )}
        <button
          onClick={handleSignOut}
          className="bg-red-100 hover:bg-red-200 text-red-900 font-medium py-2 px-4 rounded mt-4"
        >
          Sign Out
        </button>
      </div>
    </Layout>
  );
};

export default Settings;
