import React, { useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

const EditCommaSeparatedList = ({ value, onChange }) => {
  const safeValue = value || "";
  const items =
    safeValue === "" ? [] : safeValue.split(",").map((item) => item.trim());
  const [editingIndex, setEditingIndex] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [error, setError] = useState("");

  const validatePhoneNumber = (number) => {
    if (!number) return "Phone number is required";
    if (!isValidPhoneNumber(number))
      return "Invalid phone number for selected country";
    return "";
  };

  const handleItemChange = (newValue, index) => {
    setError("");
    const validationError = validatePhoneNumber(newValue);
    if (!validationError) {
      const updatedItems = [...items];
      updatedItems[index] = newValue;
      onChange(updatedItems.join(","));
    } else {
      setError(validationError);
    }
  };

  const handleAddItem = () => {
    setIsAdding(true);
    setNewPhoneNumber("");
    setError("");
  };

  const handleSaveNew = () => {
    const validationError = validatePhoneNumber(newPhoneNumber);
    if (validationError) {
      setError(validationError);
      return;
    }

    const updatedItems = [...items, newPhoneNumber];
    onChange(updatedItems.join(","));
    setIsAdding(false);
    setNewPhoneNumber("");
    setError("");
  };

  const handleRemoveItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    onChange(updatedItems.join(","));
    setError("");
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setError("");
  };

  const handleSaveEdit = (index) => {
    const validationError = validatePhoneNumber(items[index]);
    if (validationError) {
      setError(validationError);
      return;
    }
    setEditingIndex(null);
    setError("");
  };

  const inputStyle =
    "flex-grow border rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200";

  return (
    <div className="space-y-4">
      {error && (
        <div className="text-red-500 text-sm bg-red-50 border border-red-100 p-3 rounded-lg flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
          {error}
        </div>
      )}

      {items.map((item, index) => (
        <div key={index} className="flex items-center gap-3">
          {editingIndex === index ? (
            <>
              <PhoneInput
                international
                defaultCountry="US"
                value={item}
                onChange={(newValue) => handleItemChange(newValue, index)}
                className={inputStyle}
                error={error && "Invalid phone number"}
              />
              <button
                onClick={() => handleSaveEdit(index)}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200"
              >
                Save
              </button>
            </>
          ) : (
            <>
              <div className={`${inputStyle} bg-gray-50`}>{item}</div>
              <button
                onClick={() => handleEdit(index)}
                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
              </button>
            </>
          )}
          <button
            onClick={() => handleRemoveItem(index)}
            className="inline-flex items-center p-2 border border-transparent text-sm rounded-lg text-gray-400 hover:text-red-500 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-all duration-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      ))}

      {isAdding ? (
        <div className="flex items-center gap-3 mt-4">
          <PhoneInput
            international
            defaultCountry="US"
            value={newPhoneNumber}
            onChange={setNewPhoneNumber}
            className={inputStyle}
            error={error && "Invalid phone number"}
          />
          <button
            onClick={handleSaveNew}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200"
          >
            Save
          </button>
          <button
            onClick={() => {
              setIsAdding(false);
              setError("");
            }}
            className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200"
          >
            Cancel
          </button>
        </div>
      ) : (
        <button
          onClick={handleAddItem}
          className="inline-flex items-center px-3 py-2 border border-gray-200 text-sm font-medium rounded-lg text-gray-600 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
          Add Phone Number
        </button>
      )}
    </div>
  );
};

export default EditCommaSeparatedList;
