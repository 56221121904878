import React from 'react';
import { CheckCircle } from 'lucide-react';

export function AboutPanelPage() {
  return (
    <div className="min-h-screen bg-white text-black flex flex-col">
      {/* Header */}
      <header className="border-b border-gray-200">
        <div className="container mx-auto px-4 py-6 flex justify-between items-center">
          <div className="text-2xl font-bold flex items-center">P A N Ǝ L</div>
          <nav>
            <ul className="flex space-x-4 text-sm font-medium">
              {/* For the bullet separators in your screenshot, you can add them manually if you like. */}
              <li><a href="/panel" className="text-black hover:text-gray-700 transition">Home</a></li>
              <li><a href="mailto:hello@share.vc" className="text-black hover:text-gray-700 transition">Contact</a></li>
            </ul>
          </nav>
        </div>
      </header>

      {/* About Panel Title (Centered) */}
      <section className="container mx-auto px-4 py-10 md:py-14 text-center">
        <h1 className="text-3xl md:text-4xl font-bold mb-2">About PANEL</h1>
        <p className="text-base md:text-lg text-gray-700 max-w-3xl mx-auto">
          PANEL is a first-of-its-kind intelligent compliance management system 
          designed specifically for the GLP-1 ecosystem. By integrating 1440's 
          generative AI-powered behavior change platform with comprehensive protocol 
          management, PANEL delivers hyper-personalized coaching while ensuring 
          adherence to critical treatment components at scale.
        </p>
      </section>

      {/* Main Content */}
      <main className="flex-grow">
        {/* Two-Column Section: Overview & Key Challenges */}
        <section className="container mx-auto px-4 py-10 md:py-14 grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Column */}
          <div className="space-y-6">
            <h2 className="text-2xl md:text-3xl font-bold">How PANEL Works</h2>
            <p className="text-gray-700">
              Our system transforms the traditional approach to GLP-1 therapy management 
              by combining real-time monitoring, predictive interventions, and connected 
              coaching that maintains the human touch—all while operating at scale. 
              Patients receive customized support for exercise, nutrition, and adherence, 
              while providers get data-driven insights and automated compliance tracking.
            </p>
          </div>

          {/* Right Column */}
          <div className="space-y-6">
            <h2 className="text-2xl md:text-3xl font-bold">Key Challenges</h2>
            <ul className="list-disc list-inside text-gray-700 ml-4 space-y-2">
              <li>68% of patients struggle with recommended exercise routines</li>
              <li>Only 47% consistently follow prescribed nutritional guidance</li>
              <li>35% increase in muscle mass loss without proper protocol adherence</li>
            </ul>
          </div>
        </section>

        {/* Why PANEL is Different */}
        <section className="container mx-auto px-4 py-10 md:py-14">
          <h2 className="text-2xl md:text-3xl font-bold mb-6 text-center">
            Why PANEL is Different
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="flex flex-col">
              <div className="flex items-start space-x-2">
                <CheckCircle className="mt-1 w-5 h-5 text-black" />
                <h3 className="font-bold text-base">Automated Compliance</h3>
              </div>
              <p className="text-gray-700 mt-2">
                Significantly reduces administrative burden, allowing providers 
                to focus on delivering the best care.
              </p>
            </div>
            <div className="flex flex-col">
              <div className="flex items-start space-x-2">
                <CheckCircle className="mt-1 w-5 h-5 text-black" />
                <h3 className="font-bold text-base">AI-Powered Engagement</h3>
              </div>
              <p className="text-gray-700 mt-2">
                Keeps patients on track with personalized alerts, proactive 
                nudges, and dynamic goal setting.
              </p>
            </div>
            <div className="flex flex-col">
              <div className="flex items-start space-x-2">
                <CheckCircle className="mt-1 w-5 h-5 text-black" />
                <h3 className="font-bold text-base">Actionable Insights</h3>
              </div>
              <p className="text-gray-700 mt-2">
                Data-driven analysis lets providers adjust treatment protocols 
                for optimal outcomes.
              </p>
            </div>
          </div>
        </section>

        {/* Platform Features (Cards) */}
        <section className="container mx-auto px-4 py-10 md:py-14">
          <h2 className="text-2xl md:text-3xl font-bold mb-6 text-center">
            Platform Features
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              'Hyper-personalized connected coaching',
              'Real-time intervention alerts',
              'Integrated sensor & behavioral data',
              'Predictive risk assessment',
              'Automated compliance tracking',
              'Personalized nutrition & exercise support',
              'Provider dashboard & actionable insights',
              'Scalable patient support infrastructure',
            ].map((feature, index) => (
              <div
                key={index}
                className="bg-white border border-gray-200 rounded-lg p-4 shadow-sm flex flex-col"
              >
                <h4 className="text-sm md:text-base font-bold mb-2">
                  {feature}
                </h4>
                <p className="text-sm text-gray-700">
                  PANEL’s adaptive system ensures each patient’s plan is 
                  continually optimized for strong engagement and measurable results.
                </p>
              </div>
            ))}
          </div>
        </section>

        {/* Final CTA */}
        <section className="container mx-auto px-4 py-10 md:py-14 text-center">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">
            Ready to Transform Your GLP-1 Practice?
          </h2>
          <p className="text-gray-700 max-w-xl mx-auto mb-8">
            Join PANEL to harness the power of real-time insights, personalized 
            coaching, and advanced compliance tracking—delivering better outcomes 
            for your patients with fewer manual tasks.
          </p>
          <a
            href="mailto:hello@share.vc"
            className="inline-block bg-black text-white py-3 px-6 rounded hover:bg-gray-800 transition font-medium"
          >
            Get Started Today
          </a>
        </section>
      </main>
    </div>
  );
}

export default AboutPanelPage;
