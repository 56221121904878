import React from "react";
import Layout from "./Layout";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  Users,
  MessageCircle,
  AlertCircle,
  Activity,
  UserCheck,
  TrendingUp,
  Clock,
  Flag,
} from "lucide-react";

const Dashboard = () => {
  // Sample engagement data
  const engagementData = [
    { day: "Mon", rate: 82 },
    { day: "Tue", rate: 85 },
    { day: "Wed", rate: 87 },
    { day: "Thu", rate: 84 },
    { day: "Fri", rate: 89 },
    { day: "Sat", rate: 88 },
    { day: "Sun", rate: 87 },
  ];

  return (
    <Layout title="Dashboard (Sample Data)">
      <div className="bg-gray-50 min-h-screen">
        <div className="max-w-7xl mx-auto space-y-8">
          {/* Key Metrics */}
          <div className="grid grid-cols-3 gap-6">
            <div className="col-span-2 bg-white rounded-2xl shadow p-8 border border-gray-100">
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center gap-3">
                  <div className="bg-blue-500/10 p-2 rounded-lg">
                    <TrendingUp className="h-5 w-5 text-blue-500" />
                  </div>
                  <h2 className="text-lg font-semibold mb-0 text-gray-900">
                    Weekly Engagement Rate
                  </h2>
                </div>
                <div className="flex items-center gap-2 bg-green-100 px-3 py-1 rounded-full">
                  <span className="text-green-600 text-sm font-medium">
                    +12.4%
                  </span>
                </div>
              </div>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={engagementData}>
                    <defs>
                      <linearGradient
                        id="rateGradient"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                      >
                        <stop
                          offset="5%"
                          stopColor="#3B82F6"
                          stopOpacity={0.2}
                        />
                        <stop
                          offset="95%"
                          stopColor="#3B82F6"
                          stopOpacity={0}
                        />
                      </linearGradient>
                    </defs>
                    <CartesianGrid vertical={false} stroke="#f0f0f0" />
                    <XAxis
                      dataKey="day"
                      axisLine={false}
                      tickLine={false}
                      tick={{ fill: "#6B7280", fontSize: 12 }}
                    />
                    <YAxis
                      axisLine={false}
                      tickLine={false}
                      tick={{ fill: "#6B7280", fontSize: 12 }}
                      domain={[60, 100]}
                    />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: "#fff",
                        border: "none",
                        borderRadius: "8px",
                        boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1)",
                      }}
                    />
                    <Line
                      type="monotone"
                      dataKey="rate"
                      stroke="#3B82F6"
                      strokeWidth={3}
                      dot={false}
                      fill="url(#rateGradient)"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div>
              <div className="bg-white rounded-xl mb-6 shadow p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold m-0">Active Clients</h2>
                  <Users className="h-5 w-5 text-blue-500" />
                </div>
                <div className="text-3xl font-bold text-gray-900 m-0">24</div>
                <div className="flex items-center mt-2">
                  <span className="text-green-500 text-sm font-medium m-0">
                    ↑ 8.2%
                  </span>
                  <span className="text-gray-500 text-sm ml-2 m-0">
                    vs last month
                  </span>
                </div>
              </div>

              <div className="bg-white rounded-xl shadow p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold m-0">Total Messages</h2>
                  <MessageCircle className="h-5 w-5 text-blue-500" />
                </div>
                <div className="text-3xl font-bold text-gray-900 m-0">
                  1,247
                </div>
                <div className="flex items-center mt-2">
                  <span className="text-green-500 text-sm font-medium m-0">
                    ↑ 12.5%
                  </span>
                  <span className="text-gray-500 text-sm ml-2 m-0">
                    vs last month
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* Priority Actions */}
          <div className="bg-white rounded-xl shadow p-6">
            <div className="flex items-center gap-2 mb-6">
              <AlertCircle className="h-5 w-5 text-amber-500" />
              <h2 className="text-lg font-semibold m-0">Priority Actions</h2>
            </div>
            <div className="space-y-4">
              <div className="flex items-center p-5 bg-amber-50 rounded-xl border border-amber-200 hover:bg-amber-100 transition-colors">
                <div className="flex-1">
                  <div className="flex items-center gap-2">
                    <Clock className="h-4 w-4 text-amber-600" />
                    <p className="font-semibold text-amber-900 m-0">
                      3 clients need check-in
                    </p>
                  </div>
                  <p className="text-sm text-amber-700 mt-1 m-0">
                    No interaction in the last 7 days
                  </p>
                </div>
                <button className="px-6 py-2.5 bg-amber-200 text-amber-900 rounded-lg text-sm font-medium hover:bg-amber-300 transition-colors shadow-sm">
                  Review Now
                </button>
              </div>

              <div className="flex items-center p-5 bg-blue-50 rounded-xl border border-blue-200 hover:bg-blue-100 transition-colors">
                <div className="flex-1">
                  <div className="flex items-center gap-2">
                    <Flag className="h-4 w-4 text-blue-600" />
                    <p className="font-semibold text-blue-900 m-0">
                      5 messages need review
                    </p>
                  </div>
                  <p className="text-sm text-blue-700 mt-1 m-0">
                    AI flagged for human review
                  </p>
                </div>
                <button className="px-6 py-2.5 bg-blue-200 text-blue-900 rounded-lg text-sm font-medium hover:bg-blue-300 transition-colors shadow-sm">
                  Check Now
                </button>
              </div>
            </div>
          </div>

          {/* Recent Activity & Active Protocols */}
          <div className="grid grid-cols-2 gap-6">
            <div className="bg-white rounded-xl shadow p-6">
              <div className="flex items-center gap-2 mb-6">
                <Activity className="h-5 w-5 text-indigo-500" />
                <h2 className="text-lg font-semibold m-0">
                  Recent Client Activity
                </h2>
              </div>
              <div className="space-y-6">
                {[
                  {
                    user:
                      "+1" +
                      Math.floor(Math.random() * 9000000000 + 1000000000),
                    action: "Completed daily check-in",
                    time: "2h ago",
                    status: "success",
                  },
                  {
                    user:
                      "+45" + Math.floor(Math.random() * 90000000 + 10000000),
                    action: "Started new protocol",
                    time: "4h ago",
                    status: "info",
                  },
                  {
                    user:
                      "+33" + Math.floor(Math.random() * 900000000 + 100000000),
                    action: "Missed check-in",
                    time: "1d ago",
                    status: "warning",
                  },
                ].map((activity, i) => (
                  <div key={i} className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                      <div
                        className={`w-10 h-10 rounded-full flex items-center justify-center ${
                          activity.status === "success"
                            ? "bg-green-100"
                            : activity.status === "warning"
                            ? "bg-amber-100"
                            : "bg-blue-100"
                        }`}
                      >
                        <UserCheck
                          className={`h-5 w-5 ${
                            activity.status === "success"
                              ? "text-green-600"
                              : activity.status === "warning"
                              ? "text-amber-600"
                              : "text-blue-600"
                          }`}
                        />
                      </div>
                      <div>
                        <p className="font-medium text-gray-900 m-0">
                          {activity.user}
                        </p>
                        <p className="text-sm text-gray-500 m-0">
                          {activity.action}
                        </p>
                      </div>
                    </div>
                    <span className="text-sm text-gray-500 m-0">
                      {activity.time}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-white rounded-xl shadow p-6">
              <div className="flex items-center gap-2 mb-6">
                <Activity className="h-5 w-5 text-violet-500" />
                <h2 className="text-lg font-semibold m-0">Active Protocols</h2>
              </div>
              <div className="space-y-6">
                {[
                  { name: "Fitness Tracking", clients: 8, completion: 75 },
                  { name: "Nutrition Planning", clients: 12, completion: 82 },
                  { name: "Mental Wellness", clients: 6, completion: 90 },
                ].map((protocol, i) => (
                  <div key={i} className="space-y-2">
                    <div className="flex justify-between items-center">
                      <span className="font-medium text-gray-900 m-0">
                        {protocol.name}
                      </span>
                      <span className="text-sm text-gray-500 m-0">
                        {protocol.clients} active clients
                      </span>
                    </div>
                    <div className="w-full bg-gray-100 rounded-full h-2.5">
                      <div
                        className="bg-violet-500 h-2.5 rounded-full transition-all duration-500"
                        style={{ width: `${protocol.completion}%` }}
                      />
                    </div>
                    <div className="flex justify-between items-center text-sm">
                      <span className="text-gray-500 m-0">Progress</span>
                      <span className="font-medium text-violet-600 m-0">
                        {protocol.completion}%
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
