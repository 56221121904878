import React, { useState, useEffect } from "react";
import axios from "axios";
import JsonEditor from "react-json-editor-ui";
import "react-json-editor-ui/dist/react-json-editor-ui.cjs.development.css";

function ProtocolEditor({ data, onChange }) {
  // Add these default values at the top of the component
  const DEFAULT_PUNISHABLE =
    "## You will be penalized for the following: \n1) You ask multiple questions. 2) Your sentence structure is the same as your previous messages. 3) You overuse the user's name. 4) You overuse emojis. 5) You either append example responses or explain why you asked AFTER your question instead of ending the message on the question. 7) You speak like an AI (overly affirmative, positively reinforcing, subservient and agreeable) rather than a personal coach (understanding, assertive and contextually aware).";
  const DEFAULT_REWARDABLE =
    "## You will be rewarded for the following: \n1) Your response shows a deep understanding of what has been said so far. 2) You recognize their underlying emotions and react to their cues, no matter how subtle. 3) You adjusts formality and vocabulary to align with the user's style. 4) You sometimes use micro-commitments. 5) You recognize when to slow down, change the conversation topic or modify your approach if the user seems hesitant or disengaged. 6) Your response style and length matches the user's messages (you should often have short responses). 7) You end your message after a question mark without appending anything afterwards.";

  // We'll work with the first protocol item since that's the standard structure
  const protocol = data[0] || {};

  // Add useEffect to set default values when protocol is empty or missing these fields
  useEffect(() => {
    if (!protocol.punishableBehavior || !protocol.rewardableBehavior) {
      const updates = {};
      if (!protocol.punishableBehavior) {
        updates.punishableBehavior = DEFAULT_PUNISHABLE;
      }
      if (!protocol.rewardableBehavior) {
        updates.rewardableBehavior = DEFAULT_REWARDABLE;
      }

      if (Object.keys(updates).length > 0) {
        const newProtocol = { ...protocol, ...updates };
        onChange([newProtocol]);
      }
    }
  }, []);

  const updateField = (field, value) => {
    const newProtocol = { ...protocol, [field]: value };
    onChange([newProtocol]);
  };

  const updateModulesItem = (index, field, value) => {
    const newModules = [...protocol.modules];
    newModules[index] = { ...newModules[index], [field]: value };
    updateField("modules", newModules);
  };

  const addModulesItem = () => {
    const newModules = [...protocol.modules, { name: "", note: "" }];
    updateField("modules", newModules);
  };

  const deleteModuelsItem = (index) => {
    const newModules = protocol.modules.filter((_, i) => i !== index);
    updateField("modules", newModules);
  };

  const updateTask = (index, field, value) => {
    const newTouchpoints = [...protocol.touchpoints];
    newTouchpoints[index] = { ...newTouchpoints[index], [field]: value };
    updateField("touchpoints", newTouchpoints);
  };

  const addTask = () => {
    const newTask = {
      description: "",
      execute_at: new Date().toISOString(),
      type: "daily",
      task_id: Date.now().toString(),
      editable: true,
      modality: "Text",
      musicType: "Motivational",
    };
    updateField("touchpoints", [...protocol.touchpoints, newTask]);
  };

  const deleteTask = (index) => {
    const newTouchpoints = protocol.touchpoints.filter((_, i) => i !== index);
    updateField("touchpoints", newTouchpoints);
  };

  return (
    <div className="space-y-6">
      {/* Basic Information */}
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-gray-300 text-sm font-medium mb-2">
            Phone
          </label>
          <input
            type="text"
            value={protocol.phone || ""}
            onChange={(e) => updateField("phone", e.target.value)}
            className="w-full bg-slate-600 text-white rounded-md p-2"
          />
        </div>
        <div>
          <label className="block text-gray-300 text-sm font-medium mb-2">
            Coach Name
          </label>
          <input
            type="text"
            value={protocol.coach_name || ""}
            onChange={(e) => updateField("coach_name", e.target.value)}
            className="w-full bg-slate-600 text-white rounded-md p-2"
          />
        </div>
      </div>

      {/* Module and Prompt */}
      <div>
        <label className="block text-gray-300 text-sm font-medium mb-2">
          Current Module
        </label>
        <input
          type="text"
          value={protocol.current_module || ""}
          onChange={(e) => updateField("current_module", e.target.value)}
          className="w-full bg-slate-600 text-white rounded-md p-2"
        />
      </div>

      <div>
        <label className="block text-gray-300 text-sm font-medium mb-2">
          Current Prompt
        </label>
        <textarea
          value={protocol.current_prompt || ""}
          onChange={(e) => updateField("current_prompt", e.target.value)}
          className="w-full bg-slate-600 text-white rounded-md p-2 min-h-[100px]"
        />
      </div>

      <div>
        <label className="block text-gray-300 text-sm font-medium mb-2">
          Personality
        </label>
        <textarea
          value={protocol.personality || ""}
          onChange={(e) => updateField("personality", e.target.value)}
          className="w-full bg-slate-600 text-white rounded-md p-2 min-h-[100px]"
        />
      </div>

      <div>
        <label className="block text-gray-300 text-sm font-medium mb-2">
          Punish And Reward
        </label>
        <div className="space-y-4">
          <div>
            <label className="block text-gray-300 text-sm font-medium mb-1">
              Punishable Behavior
            </label>
            <textarea
              value={protocol.punishableBehavior || DEFAULT_PUNISHABLE}
              onChange={(e) =>
                updateField("punishableBehavior", e.target.value)
              }
              className="w-full bg-slate-600 text-white rounded-md p-2 min-h-[100px]"
              placeholder="Describe punishable behavior..."
            />
            <p className="text-gray-400 text-xs mt-1">
              Describe the behavior that should be punished. Remember to start
              off your text by writing "## You will be penalized for the
              following: " - And then list the things that you want to punish it
              for.
            </p>
          </div>
          <div>
            <label className="block text-gray-300 text-sm font-medium mb-1">
              Rewardable Behavior
            </label>
            <textarea
              value={protocol.rewardableBehavior || DEFAULT_REWARDABLE}
              onChange={(e) =>
                updateField("rewardableBehavior", e.target.value)
              }
              className="w-full bg-slate-600 text-white rounded-md p-2 min-h-[100px]"
              placeholder="Describe rewardable behavior..."
            />
            <p className="text-gray-400 text-xs mt-1">
              Describe the behavior that should be rewarded. Remember to start
              off your text by writing "## You will be rewarded for the
              following: " - And then list the things that you want to reward it
              for.
            </p>
          </div>
        </div>
      </div>

      <div>
        <div className="flex justify-between items-center mb-2">
          <label className="text-gray-300 text-sm font-medium">
            Modules Items
          </label>
          <button
            onClick={addModulesItem}
            className="px-3 py-1 bg-blue-600 hover:bg-blue-700 text-white rounded-md text-sm"
          >
            Add Item
          </button>
        </div>
        <div className="space-y-3">
          {protocol.modules?.map((item, index) => (
            <div key={index} className="bg-slate-700 p-3 rounded-lg space-y-2">
              <div className="flex justify-between items-center">
                <input
                  type="text"
                  value={item.title}
                  onChange={(e) =>
                    updateModulesItem(index, "title", e.target.value)
                  }
                  placeholder="title"
                  className="flex-grow bg-slate-600 text-white rounded-md p-2 mr-2"
                />
                <button
                  onClick={() => deleteModuelsItem(index)}
                  className="text-red-400 hover:text-red-300"
                >
                  ✕
                </button>
              </div>
              <input
                value={item.note}
                onChange={(e) =>
                  updateModulesItem(index, "note", e.target.value)
                }
                placeholder="Note"
                className="w-full bg-slate-600 text-white rounded-md p-2"
              />
            </div>
          ))}
        </div>
      </div>

      {/* Tasks */}
      <div>
        <div className="flex justify-between items-center mb-2">
          <label className="text-gray-300 text-sm font-medium">Tasks</label>
          <button
            onClick={addTask}
            className="px-3 py-1 bg-blue-600 hover:bg-blue-700 text-white rounded-md text-sm"
          >
            Add Task
          </button>
        </div>
        <div className="space-y-3">
          {protocol.touchpoints?.map((task, index) => (
            <div
              key={task.task_id}
              className="bg-slate-700 p-3 rounded-lg space-y-2"
            >
              <div className="flex justify-between items-center">
                <input
                  type="text"
                  value={task.description}
                  onChange={(e) =>
                    updateTask(index, "description", e.target.value)
                  }
                  placeholder="Description"
                  className="flex-grow bg-slate-600 text-white rounded-md p-2 mr-2"
                />
                <button
                  onClick={() => deleteTask(index)}
                  className="text-red-400 hover:text-red-300"
                >
                  ✕
                </button>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <input
                  type="datetime-local"
                  value={task.execute_at.split("Z")[0]}
                  onChange={(e) =>
                    updateTask(index, "execute_at", e.target.value + "Z")
                  }
                  className="bg-slate-600 text-white rounded-md p-2"
                />
                <select
                  value={task.type}
                  onChange={(e) => updateTask(index, "type", e.target.value)}
                  className="bg-slate-600 text-white rounded-md p-2"
                >
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* First Messages */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <label className="block text-gray-800 text-lg font-semibold mb-4">
          First Messages
        </label>
        <div className="space-y-4">
          {protocol.first_message?.map((message, index) => (
            <div key={index} className="flex gap-3">
              <textarea
                value={message}
                onChange={(e) => {
                  const newMessages = [...protocol.first_message];
                  newMessages[index] = e.target.value;
                  updateField("first_message", newMessages);
                }}
                className="w-full border border-gray-200 rounded-lg p-3 min-h-[100px] text-gray-700 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 resize-none"
                placeholder="Enter your message here..."
              />
              <button
                onClick={() => {
                  const newMessages = protocol.first_message.filter(
                    (_, i) => i !== index
                  );
                  updateField("first_message", newMessages);
                }}
                className="text-gray-400 hover:text-red-500 self-start p-2 transition duration-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          ))}
          <button
            onClick={() => {
              const newMessages = [...(protocol.first_message || []), ""];
              updateField("first_message", newMessages);
            }}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-3 rounded-lg transition duration-200 flex items-center justify-center gap-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
            Add New Message
          </button>
        </div>
      </div>
    </div>
  );
}

function LogEditor({ data, onChange }) {
  const addNewMessage = () => {
    onChange([
      ...data,
      {
        role: "user",
        content: "",
        time: new Date()
          .toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false,
          })
          .replace(
            /(\d+)\/(\d+)\/(\d+), (\d+):(\d+):(\d+)/,
            "$3-$1-$2 $4:$5:$6"
          ),
        via: false,
      },
    ]);
  };

  const updateMessage = (index, field, value) => {
    const newData = [...data];
    newData[index] = { ...newData[index], [field]: value };
    onChange(newData);
  };

  const deleteMessage = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    onChange(newData);
  };

  return (
    <div className="space-y-4">
      {data.map((message, index) => (
        <div key={index} className="bg-slate-700 p-4 rounded-lg space-y-3">
          <div className="flex items-center justify-between gap-2">
            <select
              value={message.role}
              onChange={(e) => updateMessage(index, "role", e.target.value)}
              className="bg-slate-600 text-white px-3 py-1 rounded-md"
            >
              <option value="user">User</option>
              <option value="assistant">Assistant</option>
            </select>
            <select
              value={message.via || "web"}
              onChange={(e) => updateMessage(index, "via", e.target.value)}
              className="bg-slate-600 text-white px-3 py-1 rounded-md"
            >
              <option value={false}>None</option>
              <option value="app">App</option>
              <option value="whatsapp">WhatsApp</option>
              <option value="sms">SMS</option>
              <option value="messenger">Messenger</option>
              <option value="phone">Phone call</option>
            </select>

            <button
              onClick={() => deleteMessage(index)}
              className="text-red-400 hover:text-red-300"
            >
              ✕
            </button>
          </div>
          <input
            value={message.content}
            onChange={(e) => updateMessage(index, "content", e.target.value)}
            className="w-full bg-slate-600 text-white rounded-md p-2"
            placeholder="Message content..."
          />
        </div>
      ))}
      <button
        onClick={addNewMessage}
        className="w-full py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg"
      >
        Add Message
      </button>
    </div>
  );
}

export default function Personas() {
  const [personas, setPersonas] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [newPersonaName, setNewPersonaName] = useState("");
  const [loading, setLoading] = useState(false);
  const [saveStatus, setSaveStatus] = useState("idle"); // 'idle' | 'loading' | 'success'

  useEffect(() => {
    fetchPersonas();
  }, []);

  const fetchPersonas = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://api.1440.ai/api/admin/personas",
        {
          headers: {
            "Api-Key": localStorage.getItem("adminApiKey"),
          },
        }
      );
      setPersonas(response.data);
    } catch (error) {
      console.error("Error fetching personas:", error);
    } finally {
      setLoading(false);
    }
  };

  const createPersona = async () => {
    if (!newPersonaName.trim()) return;

    try {
      const response = await axios.post(
        "https://api.1440.ai/api/admin/personas",
        { name: newPersonaName },
        {
          headers: {
            "Api-Key": localStorage.getItem("adminApiKey"),
          },
        }
      );
      setPersonas([...personas, response.data]);
      setNewPersonaName("");
    } catch (error) {
      console.error("Error creating persona:", error);
    }
  };

  const deletePersona = async (id) => {
    try {
      await axios.delete(`https://api.1440.ai/api/admin/personas/${id}`, {
        headers: {
          "Api-Key": localStorage.getItem("adminApiKey"),
        },
      });
      setPersonas(personas.filter((p) => p.id !== id));
    } catch (error) {
      console.error("Error deleting persona:", error);
    }
  };

  const openPersonaEditor = async (id) => {
    try {
      const response = await axios.get(
        `https://api.1440.ai/api/admin/personas/${id}`,
        {
          headers: {
            "Api-Key": localStorage.getItem("adminApiKey"),
          },
        }
      );
      setSelectedPersona(response.data);
    } catch (error) {
      console.error("Error fetching persona details:", error);
    }
  };

  const updatePersona = async (updatedData) => {
    try {
      setSaveStatus("loading");
      const response = await axios.put(
        `https://api.1440.ai/api/admin/personas/${
          selectedPersona["user_id"].split("_")[1]
        }`,
        updatedData,
        {
          headers: {
            "Api-Key": localStorage.getItem("adminApiKey"),
          },
        }
      );
      setPersonas(
        personas.map((p) => (p.id === selectedPersona.id ? response.data : p))
      );
      setSaveStatus("success");
      // Reset button state after 1.5 seconds
      setTimeout(() => {
        setSaveStatus("idle");
      }, 1500);
    } catch (error) {
      console.error("Error updating persona:", error);
      setSaveStatus("idle");
    }
  };

  const renderSaveButtonContent = () => {
    switch (saveStatus) {
      case "loading":
        return (
          <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
              fill="none"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        );
      case "success":
        return (
          <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        );
      default:
        return "Save Changes";
    }
  };

  return (
    <div className="">
      <h2 className="text-lg font-medium text-white mb-2">Personas</h2>

      <div className="flex gap-2 mb-4">
        <input
          type="text"
          value={newPersonaName}
          onChange={(e) => setNewPersonaName(e.target.value)}
          placeholder="New persona name..."
          className="flex-grow px-3 py-2 bg-slate-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={createPersona}
          className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg"
        >
          Add
        </button>
      </div>

      <div className="space-y-2 max-h-[300px] overflow-y-auto">
        {personas.map((persona) => (
          <div
            key={persona.id}
            className="flex items-center justify-between bg-slate-700 p-2 rounded-lg"
          >
            <span className="text-white">{persona.name}</span>
            <div className="space-x-2">
              <button
                onClick={() => openPersonaEditor(persona.id)}
                className="px-2 py-1 bg-blue-600 hover:bg-blue-700 text-white rounded text-sm"
              >
                Edit
              </button>
              <button
                onClick={() => deletePersona(persona.id)}
                className="px-2 py-1 bg-red-600 hover:bg-red-700 text-white rounded text-sm"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      {selectedPersona && (
        <div className="fixed top-0 right-0 h-screen w-[600px] bg-slate-800 shadow-xl overflow-hidden">
          <div className="h-full p-6 flex flex-col">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center gap-2">
                <h3 className="text-xl font-bold text-white">
                  Edit {selectedPersona.name}
                </h3>
                <button
                  onClick={() =>
                    navigator.clipboard.writeText(selectedPersona.user_id)
                  }
                  className="px-2 py-1 bg-slate-600 hover:bg-slate-700 text-white rounded text-sm"
                >
                  Copy ID
                </button>
              </div>
              <button
                onClick={() => setSelectedPersona(null)}
                className="text-gray-400 hover:text-white"
              >
                ✕
              </button>
            </div>

            <div className="flex-1 overflow-y-auto dark">
              {[
                "log",
                "protocol",
                "core_memory",
                "structured_memory",
                "messages_count",
                "is_paying",
                "shutdown_mode",
                "summaries",
                "agents",
                "assets",
              ].map((key) => {
                if (!(key in selectedPersona)) return null;

                // Handle simple types differently
                if (
                  ["messages_count", "is_paying", "shutdown_mode"].includes(key)
                ) {
                  return (
                    <div key={key} className="mb-6">
                      <h4 className="text-gray-300 text-sm font-medium mb-2">
                        {key
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                      </h4>
                      {["is_paying", "shutdown_mode"].includes(key) ? (
                        <select
                          value={selectedPersona[key].toString()}
                          onChange={(e) => {
                            setSelectedPersona((prev) => ({
                              ...prev,
                              [key]: e.target.value === "true",
                            }));
                          }}
                          className="w-full px-3 py-2 bg-slate-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      ) : (
                        <input
                          type="number"
                          value={selectedPersona[key]}
                          onChange={(e) => {
                            setSelectedPersona((prev) => ({
                              ...prev,
                              [key]: parseInt(e.target.value) || 0,
                            }));
                          }}
                          className="w-full px-3 py-2 bg-slate-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      )}
                    </div>
                  );
                }

                // Complex types use JsonEditor
                return key === "log" ? (
                  <div key={key} className="mb-6">
                    <h4 className="text-gray-300 text-sm font-medium mb-2">
                      Full Conversation Log
                    </h4>
                    <LogEditor
                      data={selectedPersona[key]}
                      onChange={(newData) => {
                        setSelectedPersona((prev) => ({
                          ...prev,
                          [key]: newData,
                        }));
                      }}
                    />
                  </div>
                ) : key === "protocol" ? (
                  <div key={key} className="mb-6">
                    <h4 className="text-gray-300 text-sm font-medium mb-2">
                      Protocol
                    </h4>
                    <ProtocolEditor
                      data={selectedPersona[key]}
                      onChange={(newData) => {
                        setSelectedPersona((prev) => ({
                          ...prev,
                          [key]: newData,
                        }));
                      }}
                    />
                  </div>
                ) : (
                  <div key={key} className="mb-6">
                    <h4 className="text-gray-300 text-sm font-medium mb-2">
                      {key
                        .split("_")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </h4>
                    <JsonEditor
                      data={selectedPersona[key]}
                      onChange={(newData) => {
                        setSelectedPersona((prev) => ({
                          ...prev,
                          [key]: newData,
                        }));
                      }}
                      theme="dark"
                      colors={{
                        background: "#1e293b",
                        default: "#ffffff",
                        string: "#a5d6ff",
                        number: "#79c0ff",
                        boolean: "#79c0ff",
                        null: "#79c0ff",
                        key: "#d2a8ff",
                        brackets: "#8b949e",
                      }}
                    />
                  </div>
                );
              })}
            </div>

            <div className="flex justify-end space-x-2 mt-4 pt-4 border-t border-slate-700">
              <button
                onClick={() => setSelectedPersona(null)}
                className="px-4 py-2 bg-gray-600 hover:bg-gray-700 text-white rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={() => updatePersona(selectedPersona)}
                disabled={saveStatus !== "idle"}
                className={`px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg flex items-center justify-center min-w-[120px] ${
                  saveStatus !== "idle" ? "opacity-75 cursor-not-allowed" : ""
                }`}
              >
                {renderSaveButtonContent()}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
