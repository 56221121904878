import React, { useState, useEffect } from "react";
import ChatBox from "./ChatBox";
import Navbar from "../header/Navbar";
import SyncPhone from "./SyncPhone";
import Dashboard from "./Dashboard";
import axios from "axios";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import Subscribe from "../Subscribe";
import { useNavigate } from "react-router-dom";

function Coach() {
  const [userInput, setUserInput] = useState("");
  const [conversation, setConversation] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [showSyncPhone, setShowSyncPhone] = useState(false);
  const [view, setView] = useState("Dashboard");
  const [user, setUser] = useState({});
  const { phone } = useParams();
  const navigate = useNavigate(); // Import useNavigate from 'react-router-dom'

  const [darkTheme, setDarkTheme] = useState(() => {
    const savedTheme = localStorage.getItem("darkTheme");
    return savedTheme !== null ? JSON.parse(savedTheme) : true;
  });
  const mobile1440Id = Cookies.get("1440_mobile_id");
  const sub1440Id = Cookies.get("1440_user_id"); // Retrieve sub_1440_id from cookies
  const accessToken = Cookies.get("1440_access_token"); // Retrieve access token from cookies

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const viewParam = searchParams.get("view");
    if (viewParam) {
      setView(viewParam);
    }
  }, []); // Add this useEffect with an empty dependency array

  useEffect(() => {
    localStorage.setItem("darkTheme", JSON.stringify(darkTheme));
    if (sub1440Id && accessToken) {
      loadPreviousMessages();
    }
  }, [darkTheme, sub1440Id, accessToken]);

  useEffect(() => {
    if (!sub1440Id || !accessToken) {
      setShowSyncPhone(true); // Show phone sync if either is missing
    } else {
      fetchUser();
      const isPhoneSynced = mobile1440Id.startsWith("+");
      setShowSyncPhone(!isPhoneSynced);
    }
  }, [mobile1440Id, accessToken]);

  const loadPreviousMessages = async () => {
    if (!sub1440Id) return;
    try {
      const response = await axios.post("/api/get_previous_session", {
        uid: sub1440Id,
      });

      if (response.status !== "false" && Array.isArray(response.data)) {
        setConversation(response.data);
      } else {
        setConversation([]); // Ensure we set an empty array if response is invalid
      }
    } catch (error) {
      console.log("Error loading previous messages:", error);
      setConversation([]); // Ensure we set an empty array on error
    }
  };

  const toggleView = (newView) => {
    setView(newView);
  };

  const sendMessage = async (message) => {
    // if (!sub1440Id.startsWith("+")) {
    //   setShowSyncPhone(true);
    //   return;
    // }
    // TODO : needed
    // if (!sub1440Id && !accessToken) {
    //   setShowSyncPhone(true);
    //   return;
    // }

    setConversation((prevConversation) => [
      ...prevConversation,
      { role: "user", content: message },
    ]);
    showTypingIndicator();

    try {
      const response = await axios.post("/api/coach", {
        body: message,
        uid: sub1440Id,
      });

      setConversation((prevConversation) => [
        ...prevConversation,
        { role: "assistant", content: response.data.answer },
      ]);
    } catch (error) {
      setConversation((prevConversation) => [
        ...prevConversation,
        { role: "assistant", content: "An error occurred. Please try again." },
      ]);
    } finally {
      hideTypingIndicator();
    }
  };

  const fetchUser = async () => {
    try {
      const response = await axios.get(`/users/${sub1440Id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setUser(response.data);
    } catch (error) {
      console.error("Error fetching user:", error);

      // Remove the relevant cookies
      Cookies.remove("1440_access_token");
      Cookies.remove("1440_user_id");
      Cookies.remove("1440_mobile_id");
      // Reload the page to reflect the changes
      window.location.reload();
    }
  };

  const showTypingIndicator = () => {
    setIsTyping(true);
  };

  const hideTypingIndicator = () => {
    setIsTyping(false);
  };

  const handlePhoneSync = (phoneNumber) => {
    Cookies.set("1440_mobile_id", phoneNumber, { expires: 7 });
    setShowSyncPhone(false);
    window.location.reload();
  };

  const closeSyncPhone = () => {
    setShowSyncPhone(false);
  };

  return (
    <div
      className={`App${
        darkTheme ? " dark" : ""
      } flex flex-col min-h-screen bg-white dark:bg-black text-black dark:text-white`}
    >
      <header className="bg-white dark:bg-black px-6">
        <Navbar
          userId={sub1440Id}
          onToggleView={(newView) => toggleView(newView)}
          view={view}
        />
      </header>
      {showSyncPhone ? (
        <SyncPhone
          onSync={handlePhoneSync}
          onCloseSync={closeSyncPhone}
          starterPhone={phone ? phone : false}
        />
      ) : view === "Dashboard" ? (
        <Dashboard user={user} />
      ) : view === "Chat" ? (
        <main className="flex-1 overflow-y-auto px-6 sm:px-10 md:px-12 bg-white dark:bg-black text-black dark:text-white">
          <ChatBox
            conversation={conversation}
            sendMessage={sendMessage}
            isTyping={isTyping}
            userInput={userInput}
            setUserInput={setUserInput}
          />
        </main>
      ) : view === "Subscribe" ? (
        <Subscribe setView={setView} />
      ) : (
        <div className="flex-1 flex items-center justify-center bg-white dark:bg-black text-black dark:text-white">
          <p className="text-xl">This page is empty.</p>
        </div>
      )}
    </div>
  );
}

export default Coach;
