import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Mixpanel from "mixpanel-browser";
import { Bars3BottomRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";

function GroupCoachingLanding() {
  const { userId } = useParams();
  const [hasAccepted, setHasAccepted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleAccept = async () => {
    setHasAccepted(true);
    try {
      await axios.post("/users/group-coaching/register", { userId });
      Mixpanel.track("Group Coaching Accepted", {
        userId,
        sessionName: "Breakthrough Group Coaching",
      });
    } catch (error) {
      console.error("Failed to register for group coaching:", error);
      setHasAccepted(false);
    }
  };
  const handleAddToCalendar = () => {
    const zoomLink = "https://us02web.zoom.us/j/89161416493";
    // Convert PST (UTC-8) to UTC by adding 8 hours
    const startTime = "2024-12-19T19:30:00Z"; // 11:30 PST = 19:30 UTC
    const endTime = "2024-12-19T20:30:00Z"; // 12:30 PST = 20:30 UTC

    const event = {
      title: "Breakthrough Group Coaching Session",
      description: `Join an intimate group coaching session designed to help you break through barriers and unlock your potential.

Zoom Meeting Link: ${zoomLink}

Please click the link above to join the session at the scheduled time.

Time: 11:30 AM PST`,
      location: "Virtual",
      startTime,
      endTime,
    };

    const googleCalendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      event.title
    )}&details=${encodeURIComponent(
      event.description
    )}&location=${encodeURIComponent(event.location)}&dates=${startTime.replace(
      /[-:]/g,
      ""
    )}/${endTime.replace(/[-:]/g, "")}`;
    window.open(googleCalendarUrl, "_blank");
  };

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Navigation */}
      <header className="fixed top-0 left-0 right-0 bg-black text-white z-50">
        <div className="container mx-auto flex justify-between items-center p-6">
          <img src="/images/logo-white.png" alt="Logo" className="h-8" />
          <nav className="hidden md:flex font-light space-x-6 items-center">
            <a
              href="https://wa.me/+16203372653?text=Hello"
              className="hover:text-[#CCFF00] text-white text-base"
              target="_blank"
              rel="noopener noreferrer"
            >
              WHATSAPP
            </a>
            <a
              href="sms:+16203372653"
              className="hover:text-[#CCFF00] text-white text-base"
              target="_blank"
              rel="noopener noreferrer"
            >
              SMS
            </a>
            <a
              href="/coach/dashboard"
              className="hover:text-[#CCFF00] text-white text-base"
            >
              COACH SIGN-IN
            </a>
            <a
              href="/user"
              className="bg-[#CCFF00] text-black px-6 py-2 rounded hover:bg-[#CCFF00]/90"
            >
              LOG IN
            </a>
          </nav>
          <div className="md:hidden">
            <button onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? (
                <XMarkIcon className="h-6 w-6" />
              ) : (
                <Bars3BottomRightIcon className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
        {isOpen && (
          <nav className="md:hidden bg-black text-white py-4 px-6">
            <a
              href="https://wa.me/+16203372653?text=Hello"
              className="block py-2 hover:text-[#CCFF00]"
              target="_blank"
              rel="noopener noreferrer"
            >
              WHATSAPP
            </a>
            <a
              href="sms:+16203372653"
              className="block py-2 hover:text-[#CCFF00]"
              target="_blank"
              rel="noopener noreferrer"
            >
              SMS
            </a>
            <a href="/coach-signin" className="block py-2 hover:text-[#CCFF00]">
              COACH SIGN-IN
            </a>
            <button className="w-full mt-4 bg-[#CCFF00] text-black px-6 py-2 rounded hover:bg-[#CCFF00]/90">
              TRY THE COACH LIVE
            </button>
          </nav>
        )}
      </header>

      {/* Main Content */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 pb-16 relative">
        {/* Background Effects */}
        <div className="absolute top-0 left-1/2 -translate-x-1/2 w-full h-full">
          <div className="absolute top-20 left-1/4 w-72 h-72 bg-[#CCFF00] rounded-full opacity-10 blur-[100px]" />
          <div className="absolute top-40 right-1/4 w-72 h-72 bg-[#CCFF00] rounded-full opacity-10 blur-[100px]" />
        </div>

        {/* Hero Section */}
        <div className="text-center relative z-10 mb-16">
          <div className="inline-block mb-4">
            <div className="inline-flex items-center px-3 py-1 rounded-full border border-[#CCFF00]/30 bg-[#CCFF00]/10 text-[#CCFF00] text-sm">
              <span className="animate-pulse mr-2">●</span> Live Group Session
            </div>
          </div>
          <h1 className="text-5xl md:text-7xl font-bold mb-6 bg-clip-text leading-tight text-transparent bg-gradient-to-b from-[#EEE] to-[#9E9E9E]">
            Breakthrough Group<br></br>Coaching Session
          </h1>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto mb-8">
            Join an intimate group coaching session powered by AI insights and
            human expertise. Experience personalized guidance in a collaborative
            environment.
          </p>
          <div className="inline-block bg-[#CCFF00]/20 text-[#CCFF00] px-4 py-2 rounded-full font-medium">
            3 spots remaining
          </div>
        </div>

        {/* Main Card */}
        <div className="relative">
          <div className="absolute inset-0 bg-gradient-to-r from-[#CCFF00]/20 to-[#9EFF00]/20 rounded-3xl blur-xl"></div>
          <div className="relative bg-gray-900/60 backdrop-blur-xl rounded-3xl border border-[#CCFF00]/20 overflow-hidden">
            {/* Session Details */}
            <div className="grid md:grid-cols-2 gap-12">
              {/* Left Column */}
              <div className="p-8 md:p-12 space-y-8">
                <div className="space-y-6">
                  <div className="flex items-center space-x-4">
                    <div className="p-3 bg-[#CCFF00]/10 rounded-xl">
                      <svg
                        className="h-6 w-6 text-[#CCFF00]"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                    <div>
                      <p className="text-gray-400 mb-0 text-sm">Date</p>
                      <p className="text-xl mb-0">
                        Thursday, December 19, 2024
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <div className="p-3 bg-[#CCFF00]/10 rounded-xl">
                      <svg
                        className="h-6 w-6 text-[#CCFF00]"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                    <div>
                      <p className="text-gray-400 text-sm mb-0">Time</p>
                      <p className="text-xl mb-0">11:30 AM PDT • 60 minutes</p>
                    </div>
                  </div>
                </div>

                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-white">
                    About This Session
                  </h3>
                  <p className="text-gray-400 leading-relaxed">
                    Join this transformative group coaching session led by an
                    experienced professional coach. You'll collaborate with
                    like-minded peers in an intimate setting to gain powerful
                    insights, overcome challenges, and develop concrete action
                    plans for achieving your goals.
                  </p>
                </div>

                {!hasAccepted ? (
                  <button
                    onClick={handleAccept}
                    className="w-full inline-flex items-center justify-center px-8 py-4 rounded-full bg-[#CCFF00] text-black font-semibold text-lg transition-all duration-200 hover:scale-105 hover:shadow-lg hover:shadow-[#CCFF00]/25"
                  >
                    Save Your Spot
                  </button>
                ) : (
                  <div className="space-y-4 text-center">
                    <div className="inline-flex items-center space-x-2 text-[#CCFF00]">
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                      <span className="font-semibold">You're In!</span>
                    </div>
                    <p className="text-gray-400">
                      You will receive a text message with session details
                      shortly.
                    </p>
                    <button
                      onClick={handleAddToCalendar}
                      className="inline-flex items-center px-6 py-3 rounded-full border border-[#CCFF00] text-[#CCFF00] hover:bg-[#CCFF00]/10 transition-all duration-150"
                    >
                      <svg
                        className="mr-2 h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                      </svg>
                      Add to Calendar
                    </button>
                  </div>
                )}
              </div>

              {/* Right Column */}
              <div className="bg-gray-900/40 p-8 md:p-12 border-l border-gray-800">
                <h3 className="text-xl font-semibold mb-8 text-white">
                  What to Expect:
                </h3>
                <div className="space-y-6">
                  {[
                    {
                      title: "Interactive Group Dynamic",
                      description:
                        "Engage in focused discussions with a small group of peers",
                    },
                    {
                      title: "Collaborative Learning",
                      description:
                        "Share experiences and learn from diverse perspectives",
                    },
                    {
                      title: "Expert Facilitation",
                      description:
                        "Professional coaching guidance throughout the session",
                    },
                    {
                      title: "Action-Oriented Outcomes",
                      description: "Leave with clear, implementable next steps",
                    },
                  ].map((item, index) => (
                    <div key={index} className="flex items-center space-x-4">
                      <div className="h-6 w-6 rounded-full bg-[#CCFF00] flex-shrink-0 flex items-center justify-center">
                        <svg
                          className="h-4 w-4 text-black"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={3}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <div>
                        <h4 className="font-medium text-lg mb-0 text-white">
                          {item.title}
                        </h4>
                        <p className="text-gray-400 mb-0 text-sm">
                          {item.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupCoachingLanding;
