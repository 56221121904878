import React, { useState } from "react";
import { Bars3BottomRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChartBarIcon,
  ChatBubbleLeftRightIcon,
  SparklesIcon,
  UserGroupIcon,
  StarIcon,
  ShieldCheckIcon,
  LightBulbIcon,
  ArrowTrendingUpIcon,
  ArrowPathIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import Mixpanel from "mixpanel-browser";
import { WhatsappIcon } from "react-share";

const WhatsAppIcon = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className} fill="currentColor">
    <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
  </svg>
);

function HirLanding() {
  const [isOpen, setIsOpen] = useState(false);

  const handleWhatsAppClick = () => {
    Mixpanel.track("WhatsApp Click", { source: "hir_landing_page" });
    window.open("https://wa.me/+16204488264?text=Hello", "_blank");
  };

  const handleSMSClick = () => {
    Mixpanel.track("SMS Click", { source: "hir_landing_page" });
    window.open("sms:+16204488264", "_blank");
  };

  return (
    <div className="min-h-screen bg-[#0A0A0A] text-white">
      {/* Navigation */}
      <header className="fixed top-0 left-0 right-0 bg-[#0A0A0A] text-white z-50">
        <div className="container mx-auto flex justify-between items-center p-6">
          <div className="flex items-center gap-2">
            <span className="text-2xl font-bold text-[#FF69B4]">hir.ai</span>
          </div>
          <nav className="hidden md:flex font-medium space-x-6 items-center">
            <button
              onClick={handleWhatsAppClick}
              className="hover:text-[#FF69B4] text-white text-base flex items-center gap-2"
            >
              <WhatsAppIcon className="w-5 h-5" />
              WHATSAPP
            </button>
            <button
              onClick={handleSMSClick}
              className="hover:text-[#FF69B4] text-white text-base flex items-center gap-2"
            >
              <PhoneIcon className="w-5 h-5" />
              SMS
            </button>
            <button
              onClick={handleWhatsAppClick}
              className="bg-[#FF69B4] text-white px-6 py-2 rounded flex items-center gap-2 hover:bg-[#FF69B4]/90"
            >
              <ArrowTrendingUpIcon className="w-5 h-5" />
              START COACHING
            </button>
          </nav>
          <div className="md:hidden">
            <button onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? (
                <XMarkIcon className="h-6 w-6" />
              ) : (
                <Bars3BottomRightIcon className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>

        {isOpen && (
          <nav className="md:hidden bg-[#0A0A0A] text-white py-4 px-6">
            <button
              onClick={handleWhatsAppClick}
              className="block py-2 hover:text-[#FF69B4] w-full text-left flex items-center gap-2"
            >
              <WhatsAppIcon className="w-5 h-5" />
              WHATSAPP
            </button>
            <button
              onClick={handleSMSClick}
              className="block py-2 hover:text-[#FF69B4] w-full text-left flex items-center gap-2"
            >
              <PhoneIcon className="w-5 h-5" />
              SMS
            </button>
            <button
              onClick={handleWhatsAppClick}
              className="w-full mt-4 bg-[#FF69B4] text-white px-6 py-2 rounded flex items-center gap-2 justify-center hover:bg-[#FF69B4]/90"
            >
              <ArrowTrendingUpIcon className="w-5 h-5" />
              START COACHING
            </button>
          </nav>
        )}
      </header>

      {/* Main Content */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-32 pb-16 relative">
        {/* Background Effects */}
        <div className="absolute top-0 left-1/2 -translate-x-1/2 w-full h-full">
          <div className="absolute top-20 left-1/4 w-72 h-72 bg-[#FF69B4] rounded-full opacity-10 blur-[100px]" />
          <div className="absolute top-40 right-1/4 w-72 h-72 bg-[#FF69B4] rounded-full opacity-10 blur-[100px]" />
        </div>

        {/* Hero Section */}
        <div className="text-center relative z-10 mb-16">
          <div className="inline-block mb-4">
            <div className="inline-flex items-center px-3 py-1 mb-3 rounded-full border border-[#FF69B4]/30 bg-[#FF69B4]/10 text-[#FF69B4] text-sm">
              <span className="animate-pulse mr-2">●</span> AI-Enhanced Dating
              Coaching
            </div>
          </div>
          <h1 className="text-5xl md:text-7xl font-bold mb-6 bg-clip-text leading-tight text-transparent bg-gradient-to-b from-white to-gray-400">
            Unlock the Science of
            <br />
            Attraction with Hir.ai
          </h1>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto mb-8">
            Empower your dating journey with cutting-edge AI-enhanced coaching.
            Our revolutionary platform combines scientific insights with
            precision data to guide you through every step.
          </p>
          <div className="flex flex-col items-center gap-4">
            <button
              onClick={handleWhatsAppClick}
              className="inline-flex items-center justify-center px-8 py-4 rounded-full bg-[#FF69B4] text-white font-semibold text-lg transition-all duration-200 hover:scale-105 hover:shadow-lg hover:shadow-[#FF69B4]/25 gap-2"
            >
              <ArrowTrendingUpIcon className="w-6 h-6" />
              Start Your Journey
            </button>
          </div>
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-3 gap-8 relative z-10 mb-16 md:mb-32">
          <div className="p-8 bg-gray-900/40 backdrop-blur-xl rounded-2xl border border-[#FF69B4]/20">
            <LightBulbIcon className="w-8 h-8 text-[#FF69B4] mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-white">
              AI-Enhanced Guidance
            </h3>
            <p className="text-gray-400">
              Real-time advice and feedback powered by state-of-the-art AI
              technology
            </p>
          </div>
          <div className="p-8 bg-gray-900/40 backdrop-blur-xl rounded-2xl border border-[#FF69B4]/20">
            <ChartBarIcon className="w-8 h-8 text-[#FF69B4] mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-white">
              Scientific Insights
            </h3>
            <p className="text-gray-400">
              Research-backed strategies combined with practical implementations
            </p>
          </div>
          <div className="p-8 bg-gray-900/40 backdrop-blur-xl rounded-2xl border border-[#FF69B4]/20">
            <StarIcon className="w-8 h-8 text-[#FF69B4] mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-white">
              24/7 Availability
            </h3>
            <p className="text-gray-400">
              Personalized coaching anytime, anywhere, tailored to your needs
            </p>
          </div>
        </div>

        {/* Features Section */}
        <div className="relative z-10 mb-16">
          <h2 className="text-3xl font-bold mb-12 text-white text-center">
            Complete Dating Intelligence System
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                icon: (
                  <ChatBubbleLeftRightIcon className="w-8 h-8 text-[#FF69B4]" />
                ),
                title: "Profile Analysis",
                description:
                  "AI-powered evaluation of your dating profile and patterns",
              },
              {
                icon: <UserGroupIcon className="w-8 h-8 text-[#FF69B4]" />,
                title: "Behavioral Insights",
                description: "Data-driven understanding of dating dynamics",
              },
              {
                icon: <StarIcon className="w-8 h-8 text-[#FF69B4]" />,
                title: "Strategic Planning",
                description: "Customized approaches for meaningful connections",
              },
              {
                icon: <ShieldCheckIcon className="w-8 h-8 text-[#FF69B4]" />,
                title: "Scientific Method",
                description:
                  "Research-backed strategies for authentic relationships",
              },
              {
                icon: <SparklesIcon className="w-8 h-8 text-[#FF69B4]" />,
                title: "Attraction Science",
                description: "Understanding the psychology of human connection",
              },
              {
                icon: <ArrowPathIcon className="w-8 h-8 text-[#FF69B4]" />,
                title: "Continuous Learning",
                description: "AI-enhanced feedback for ongoing improvement",
              },
            ].map((feature, index) => (
              <div
                key={index}
                className="p-6 bg-gray-900/40 backdrop-blur-xl rounded-2xl border border-[#FF69B4]/20"
              >
                <div className="mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-2 text-white">
                  {feature.title}
                </h3>
                <p className="text-gray-400">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Stats Section */}
        <div className="relative z-10 mb-16">
          <div className="grid md:grid-cols-3 gap-8 text-center">
            <div className="p-6">
              <div className="text-4xl font-bold text-[#FF69B4] mb-2">24/7</div>
              <p className="text-gray-400">AI coaching access</p>
            </div>
            <div className="p-6">
              <div className="text-4xl font-bold text-[#FF69B4] mb-2">95%</div>
              <p className="text-gray-400">Success rate</p>
            </div>
            <div className="p-6">
              <div className="text-4xl font-bold text-[#FF69B4] mb-2">0.5s</div>
              <p className="text-gray-400">Response time</p>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="relative">
          <div className="absolute inset-0 bg-gradient-to-r from-[#FF69B4]/20 to-[#FF69B4]/20 rounded-3xl blur-xl"></div>
          <div className="relative bg-gray-900/60 backdrop-blur-xl rounded-3xl border border-[#FF69B4]/20 p-12 text-center">
            <h2 className="text-3xl font-bold mb-4 text-white">
              Ready to Transform Your Dating Life?
            </h2>
            <p className="text-gray-400 mb-8 max-w-2xl mx-auto">
              Join successful individuals who've enhanced their dating
              experience with our AI-powered, science-based coaching platform.
            </p>
            <button
              onClick={handleWhatsAppClick}
              className="inline-flex items-center justify-center px-8 py-4 rounded-full bg-[#FF69B4] text-white font-semibold text-lg transition-all duration-200 hover:scale-105 hover:shadow-lg hover:shadow-[#FF69B4]/25 gap-2"
            >
              <ArrowTrendingUpIcon className="w-6 h-6" />
              Start Your Transformation
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HirLanding;
